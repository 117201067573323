import { createApi } from '@reduxjs/toolkit/query/react'
import {backend_server_address} from "../../../../../backendServer";
import { Message, Thread } from '../types';
import { axiosBaseQuery } from '../../../../../store/utility';

// Define a service using a base URL and expected endpoints
export const messagesAPI = createApi({
    reducerPath: 'messagesAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/messages/'}),
    tagTypes: ['Message', 'Thread'],
    endpoints: (builder) => ({
        getMessageById: builder.query<Message, number>({
            query: (id) => ({
                url: `${id}/`,
                method: 'GET',
                withCredentials:true,
            }),
            providesTags: (result) => {
                return result ? [{type: 'Message', id: result.id}] : ['Message'];
            },
        }),
        getThreadById: builder.query<Thread, string>({
            query: (id) => ({
                url: `threads/${id}/`,
                method: 'GET',
                withCredentials:true,
            }),
            providesTags: (result) => {
                return result ? [{type: 'Thread', id: result.id}] : ['Thread'];
            },
        }),
    }),
});

export const {
    endpoints,
    useGetMessageByIdQuery,
    useGetThreadByIdQuery,
} = messagesAPI;
export const messagesAPIReducer = messagesAPI.reducer;
export const messagesAPIReducerPath = messagesAPI.reducerPath;
export const messagesAPIReducerMiddleware = messagesAPI.middleware;