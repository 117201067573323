export const GET_EVENTS_START = 'GET_EVENTS_START';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAIL = 'GET_EVENTS_FAIL';

export const GET_EVENTS_SCHEMA_START = 'GET_EVENTS_SCHEMA_START';
export const GET_EVENTS_SCHEMA_SUCCESS = 'GET_EVENTS_SCHEMA_SUCCESS';
export const GET_EVENTS_SCHEMA_FAIL = 'GET_EVENTS_SCHEMA_FAIL';

export const CREATE_EVENTS_START = 'CREATE_EVENTS_START';
export const CREATE_EVENTS_SUCCESS = 'CREATE_EVENTS_SUCCESS';
export const CREATE_EVENTS_FAIL = 'CREATE_EVENTS_FAIL';

export const EDIT_EVENTS_START = 'EDIT_EVENTS_START';
export const EDIT_EVENTS_SUCCESS = 'EDIT_EVENTS_SUCCESS';
export const EDIT_EVENTS_FAIL = 'EDIT_EVENTS_FAIL';

export const DELETE_EVENTS_START = 'DELETE_EVENTS_START';
export const DELETE_EVENTS_SUCCESS = 'DELETE_EVENTS_SUCCESS';
export const DELETE_EVENTS_FAIL = 'DELETE_EVENTS_FAIL';

export const LIST_LAST_EVENTS_START = 'LIST_LAST_EVENTS_START';
export const LIST_LAST_EVENTS_SUCCESS = 'LIST_LAST_EVENTS_SUCCESS';
export const LIST_LAST_EVENTS_FAIL = 'LIST_LAST_EVENTS_FAIL';

export const LIST_CAUSES_START = 'LIST_CAUSES_START';
export const LIST_CAUSES_SUCCESS = 'LIST_CAUSES_SUCCESS';
export const LIST_CAUSES_FAIL = 'LIST_CAUSES_FAIL';

export const ADD_EVENT_CAUSE_START = 'ADD_EVENT_CAUSE_START';
export const ADD_EVENT_CAUSE_SUCCESS = 'ADD_EVENT_CAUSE_SUCCESS';
export const ADD_EVENT_CAUSE_FAIL = 'ADD_EVENT_CAUSE_FAIL';

export const REMOVE_EVENT_CAUSE_START = 'REMOVE_EVENT_CAUSE_START';
export const REMOVE_EVENT_CAUSE_SUCCESS = 'REMOVE_EVENT_CAUSE_SUCCESS';
export const REMOVE_EVENT_CAUSE_FAIL = 'REMOVE_EVENT_CAUSE_FAIL';