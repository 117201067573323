import axios from "axios";
// import store from '../../../index.js';
import * as actions from "./AuthActions";
import {backend_server_address} from "../../../backendServer";

function getCookie(name) {
    // var b = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    // return b ? b.pop() : undefined;
    // console.log(localStorage.getItem(name));
    return localStorage.getItem(name);
}

function deleteCookie(name) {
    // document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    localStorage.removeItem(name);
}

export const authStart = () => {
    // console.log(document.cookie);
    // let authenticated = isAuthenticated();
    // console.log('Authenticated: '+authenticated+'. Refresh hash: ' + getCookie('refresh_hash'));
    return {
        type: actions.AUTH_START,
        waiting_authentication: true,
        // token_access: null,
        token_access_expiration: null,
        // token_refresh: null,
        // token_refresh_expiration: null,
        authenticated:false,
        error: null,
        changePasswordStarted: false,
        recoverPasswordSuccess: null,
        loading: false
    }
};

export function isAuthenticated() {
    let hash = getCookie('refresh_hash');
    return (hash !== undefined && hash !== null);
}


export function parseJwt (token){
    if(token !== null){
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
    let dummy = {exp:null};
    return dummy;
}

export const authSuccess = (data, username) => {
    // let token = parseJwt(data.access);
    localStorage.setItem('refresh_hash', data.refresh_hash);
    return {
        // waiting_authentication: false,
        type: actions.AUTH_SUCCESS,
        // token_access: data.access,
        token_access_expiration: data.access_exp,
        // token_refresh: data.refresh,
        // token_refresh_expiration: token.exp,
        error: null,
        loading: false,
        authenticated:true,
        // User info
        username: data.username,
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        phone: data.phone,
        node_id: data.node,
        group: data.group,
        permissions: data.permissions,
        notification_preferences: data.notification_preferences,
        user_id: data.user_id,
        // user_email: token.username,
        language: data.language,
    }
};

export const authRefresh = data => {
    // localStorage.setItem('refresh_hash', data.refresh_hash);
    return {
        waiting_authentication: false,
        connection_error: false,
        type: actions.AUTH_REFRESH,
        // token_access: data.access,
        token_access_expiration: data.access_exp,
        authenticated:true,
        // User info
        username: data.username,
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        phone: data.phone,
        node_id: data.node,
        group: data.group,
        user_id: data.user_id,
        permissions: data.permissions,
        notification_preferences: data.notification_preferences,
        language: data.language,
    }
};

export const authFail = error => {
    let connection_error = true;
    if(error.response && error.response.status >= 400 && error.response.status < 500){
        connection_error = false;
    }
    return {
        type: actions.AUTH_FAIL,
        // token_access: null,
        token_access_expiration: null,
        // token_refresh: null,
        // token_refresh_expiration: null,
        error: 'Email o contraseña incorrectos.',
        authenticated:false,
        loading: false,
        connection_error: connection_error
    }
};

export const logout = () => {
    deleteCookie('refresh_hash');
    return {
        type: actions.AUTH_LOGOUT,
        connection_error: false,
        token_access: null,
        token_access_expiration: null,
        token_refresh: null,
        token_refresh_expiration: null
    }
};

export const checkAuthTimeout =  expirationTime => {
    return dispatch => {
        setTimeout(()=> {
            axios.post(backend_server_address +'/api/login/token2/refresh/', {
                refresh_hash: getCookie('refresh_hash')
            },{
                withCredentials:true,
                credentials: 'include',
            })
                .then(res => {
                    // if(! res.data.access ){
                    //     dispatch(authFail('Refresh token is not valid!'))
                    // }
                    // else{
                    // console.log(res.data);
                    dispatch(authRefresh(res.data));
                    let date_exp = new Date(0); // The 0 there is the key, which sets the date to the epoch
                    date_exp.setUTCSeconds(res.data.access_exp);
                    let date_now = new Date();
                    let seconds = ((date_exp-date_now)/1000) - 60;
                    dispatch(checkAuthTimeout(seconds ));
                    // }

                }).catch(error => {
                dispatch(authFail(error));
                //cuando falla y se corta el loop, pido en sync el refresh para arrancar de nuevo el loop cuando vuelve el wifi.
                if(error.response && error.response.status >= 400 && error.response.status < 500){
                    deleteCookie('refresh_hash');
                }
                else{
                    // Request refresh token after 5 seconds if there is a connection error
                    dispatch(checkAuthTimeout(5 ));
                    // if ('serviceWorker' in navigator && 'SyncManager' in window) {
                    //     navigator.serviceWorker.ready.then(function(reg) {
                    //         new BroadcastChannel('browser_compatibility_check');
                    //         return reg.sync.register('refresh_hash');
                    //     }).catch(function() {
                    //         dispatch(checkAuthTimeout(0.00000000001 ));
                    //     });
                    // } else {
                    //     dispatch(checkAuthTimeout(0.00000000001 ));
                    // }
                }
            })
        }, expirationTime * 1000)
    }
};

export const authLogin = (username, password) =>
    dispatch => {
        dispatch(authStart());
        axios.post(backend_server_address +'/api/login/token2/', {
            username: username,
            password: password
        }, {
            withCredentials:true
        })
            .then(res => {
                dispatch(authSuccess(res.data, username));
                dispatch(authRefresh(res.data, username));
                let date_exp = new Date(0); // The 0 there is the key, which sets the date to the epoch
                date_exp.setUTCSeconds(res.data['access_exp']);

                let date_now = new Date();
                let seconds = ((date_exp-date_now)/1000) - 60;
                dispatch(checkAuthTimeout(seconds ));
                // dispatch(getTree());

            })
            .catch( error => {
                dispatch(authFail(error));
            })

    };

export const invalidLoginFields = () => {
    return {
        type: actions.AUTH_INVALID_VALUES,
        error: 'Ingrese un email y una contraseña por favor.'
    }
}

export const recoverPasswordMail = () => {
    return {
        type: actions.RECOVER_PASSWORD_MAIL,
        recoverRequested: true
    }
};

export const recoverPasswordSuccess = () => {
    return {
        type: actions.RECOVER_PASSWORD_SUCCESS,
        changePasswordStarted: true,
        recoverPasswordSuccess: true
    }
};

export const recoverPasswordFail = error => {
    return {
        type: actions.RECOVER_PASSWORD_FAIL,
        error: error,
        changePasswordStarted: true,
        recoverPasswordSuccess: false
    }
};

export const recoverPassword = email => {
    return dispatch => {
        // let data = new FormData();
        // data.append('email',email);
        let data = {'email': email};
        axios.post(backend_server_address +'/api/login/user/recover_password/', data
        )
            .catch( error => {
                console.log("ERROR: Recover password error.")
            });
        dispatch(recoverPasswordMail());
    }
};

export const recoverPasswordChange = (newPassword,recoverToken) => {
    return dispatch => {
        // let data = new FormData();
        // data.append('new_password', newPassword);
        // data.append('recover_token', recoverToken);
        let data = {'new_password': newPassword, 'recover_token': recoverToken};
        axios.post(backend_server_address +'/api/login/user/recover_password/', data
        )
            .then(res => {
                dispatch(recoverPasswordSuccess());
            })
            .catch( error => {
                dispatch(recoverPasswordFail(error));
            })

    }
};

//Check if the token is stored in local storage and evaluate expiration date, else logout.
export const authCheckState = () => {
    return dispatch => {
        // const refreshToken = false;
        let authenticated = isAuthenticated();
        if(authenticated){
            // let data = {refresh_hash: getCookie('refresh_hash')};
            // dispatch(authSuccess(data, ''));
            dispatch(checkAuthTimeout(0.00000000001 ));
        } else {
            dispatch(logout());
        }
    }
};

export const authRefreshToken = () => {
    return dispatch => {
        // const refreshToken = false;
        // console.log('checking refresh hash');
        let authenticated = isAuthenticated();
        // console.log(authenticated);
        if(authenticated){
            let data = {refresh_hash: getCookie('refresh_hash')};
            // console.log(data);
            dispatch(authSuccess(data, ''));
            // console.log('auth success');
            dispatch(checkAuthTimeout(0.00000000001 ));
            // console.log('checkauthtimeout');
        } else {
            dispatch(logout());
        }
    }
};

// const channel = new BroadcastChannel('sw-messages');
// channel.addEventListener('message', event => {
//     if (event.data && event.data['msg'] && event.data['msg'] === 'Hello from SW'){
//         console.log('refreshing token');
//         checkAuthTimeout(0.00000000001 );
//         return dispatch => {
//             // const refreshToken = false;
//             console.log('checking refresh hash');
//             let authenticated = isAuthenticated();
//             console.log(authenticated);
//             if(authenticated){
//                 let data = {refresh_hash: getCookie('refresh_hash')};
//                 console.log(data);
//                 dispatch(authSuccess(data, ''));
//                 console.log('auth success');
//                 dispatch(checkAuthTimeout(0.00000000001 ));
//                 console.log('checkauthtimeout');
//             } else {
//                 dispatch(logout());
//             }
//         }
//     }
// });