import { createApi } from '@reduxjs/toolkit/query/react'
import {backend_server_address} from "../../../../../backendServer";
import axios from "axios";

const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: '' }) =>
        async (axiosParams) => {
            try {
                axiosParams.url = baseUrl + axiosParams.url;
                const result = await axios.request(axiosParams);
                return { data: result.data }
            } catch (axiosError) {
                let err = axiosError;
                return {
                    error: { status: err.response ? err.response.status:undefined, data: err.response ? err.response.data:undefined },
                }
            }
        };

// Define a service using a base URL and expected endpoints
export const alarmsAPI = createApi({
    reducerPath: 'alarmsAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/alarms/alarmV2/'}),
    tagTypes: ['Alarm', 'AlarmConfiguration', 'AlarmEvent'],
    endpoints: (builder) => ({
        getAlarmsReport: builder.query({
            query: (params) => ({
                url: 'report/',
                method: 'GET',
                withCredentials:true,
                params:params
            }),
            providesTags: (result, error, arg) => (result && arg && arg.node) ?
                [{ type: 'AlarmConfiguration', id:arg.node }]:['AlarmConfiguration']
        }),
        configureAlarms: builder.mutation({
            query: (params) =>({
                url: 'configure/',
                method: 'POST',
                withCredentials:true,
                data:{...params}
            }),
            // invalidatesTags: (result) => ['AlarmConfiguration'],
        }),
        getAlarms: builder.query({
            query: (params) => ({
                url: '',
                method: 'GET',
                withCredentials:true,
                params:params
            }),
            providesTags: (result) => {
                return result ?
                    [...result.map(alarm => ({ type:'Alarm', id:alarm.id})), 'Alarm']
                    :[ 'Alarm' ]
            }
        }),
    }),
});

export const {
    useGetAlarmsReportQuery,
    useConfigureAlarmsMutation,
    useGetAlarmsQuery,
} = alarmsAPI;
export const alarmsAPIReducer = alarmsAPI.reducer;
export const alarmsAPIReducerPath = alarmsAPI.reducerPath;
export const alarmsAPIReducerMiddleware = alarmsAPI.middleware;