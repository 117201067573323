export const USERS_CREATE_START = 'USERS_CREATE_START';
export const USERS_CREATE_SUCCESS = 'USERS_CREATE_SUCCESS';
export const USERS_CREATE_FAIL = 'USERS_CREATE_FAIL';

export const USERS_EDIT_START = 'USERS_EDIT_START';
export const USERS_EDIT_SUCCESS = 'USERS_EDIT_SUCCESS';
export const USERS_EDIT_FAIL = 'USERS_EDIT_FAIL';

export const USERS_DELETE_START = 'USERS_DELETE_START';
export const USERS_DELETE_SUCCESS = 'USERS_DELETE_SUCCESS';
export const USERS_DELETE_FAIL = 'USERS_DELETE_FAIL';

export const USERS_GET_START = 'USERS_GET_START';
export const USERS_GET_SUCCESS = 'USERS_GET_SUCCESS';
export const USERS_GET_FAIL = 'USERS_GET_FAIL';

export const USERS_GET_LIST_START = 'USERS_GET_LIST_START';
export const USERS_GET_LIST_SUCCESS = 'USERS_GET_LIST_SUCCESS';
export const USERS_GET_LIST_FAIL = 'USERS_GET_LIST_FAIL';

export const USERS_GET_SCHEMA_START = 'USERS_GET_SCHEMA_START';
export const USERS_GET_SCHEMA_SUCCESS = 'USERS_GET_SCHEMA_SUCCESS';
export const USERS_GET_SCHEMA_FAIL = 'USERS_GET_SCHEMA_FAIL';

export const USERS_CHANGE_PASSWORD_START = 'USERS_CHANGE_PASSWORD_START';
export const USERS_CHANGE_PASSWORD_SUCCESS = 'USERS_CHANGE_PASSWORD_SUCCESS';
export const USERS_CHANGE_PASSWORD_FAIL = 'USERS_CHANGE_PASSWORD_FAIL';