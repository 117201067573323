import { createApi } from '@reduxjs/toolkit/query/react'
import {backend_server_address} from "../../../../../backendServer";
import axios from "axios";

const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: '' }) =>
        async (axiosParams) => {
            try {
                axiosParams.url = baseUrl + axiosParams.url;
                const result = await axios.request(axiosParams);
                return { data: result.data }
            } catch (axiosError) {
                let err = axiosError;
                return {
                    error: { status: err.response ? err.response.status:undefined, data: err.response ? err.response.data:undefined },
                }
            }
        };

// Define a service using a base URL and expected endpoints
export const notificationsAPI = createApi({
    reducerPath: 'notificationsAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/notifications/'}),
    tagTypes: ['Notification', 'NotificationCount', 'NotificationPreference', 'TelegramChat', 'TelegramMessage'],
    endpoints: (builder) => ({
        getRead: builder.query({
            query: (params) => ({
                url: 'read/list/',
                method: 'GET',
                withCredentials:true,
                params:params
            }),
            providesTags: (result) => {
                return result ?
                    [...result.map(notification => ({ type:'Notification', id:notification.id})), 'Notification']
                    :[ 'Notification' ]
            }
        }),
        getUnread: builder.query({
            query: (params) => ({
                url: 'unread/list/',
                method: 'GET',
                withCredentials:true,
                params:params
            }),
            providesTags: (result) => {
                return result ?
                    [...result.map(notification => ({ type:'Notification', id:notification.id})), 'Notification']
                    :[ 'Notification' ]
            }
        }),
        getUnreadCount: builder.query({
            query: () => ({
                url: 'unread/count/',
                method: 'GET',
                withCredentials:true,
                // params:params
            }),
            providesTags: (result) => {
                return result ? [{type:'NotificationCount', id: 'unread'}] : [ 'NotificationCount' ]
            }
        }),
        getReadCount: builder.query({
            query: () => ({
                url: 'read/count/',
                method: 'GET',
                withCredentials:true,
                // params:params
            }),
            providesTags: (result) => {
                return result ? [{type:'NotificationCount', id: 'read'}] : [ 'NotificationCount' ]
            }
        }),
        getUnreadCountByVerb: builder.query({
            query: (params) => ({
                url: 'unread/count/',
                method: 'GET',
                withCredentials:true,
                params:params
            }),
            providesTags: (result, error, arg) => {
                return result ?
                    (result.data && result.data.verb) ? [{type:'NotificationCount', id: result.data.verb}] : [{type:'NotificationCount'}]
                        :
                    [ 'NotificationCount' ]
            }
        }),
        setAsReadById: builder.mutation({
            query: (id) =>({
                url: 'unread/mark/',
                method: 'POST',
                withCredentials:true,
                data:{id:id}
            }),
            invalidatesTags: (result) => result ? [{ type: 'Notification', id:result.id }, 'NotificationCount']:['NotificationCount'],
        }),
        setAsUnreadById: builder.mutation({
            query: (id) =>({
                url: 'read/mark/',
                method: 'POST',
                withCredentials:true,
                data:{id:id}
            }),
            invalidatesTags: (result) => result ? [{ type: 'Notification', id:result.id }, 'NotificationCount']:['NotificationCount'],
        }),
        setAsReadByTicket: builder.mutation({
            query: (id) =>({
                url: 'unread/mark/',
                method: 'POST',
                withCredentials:true,
                data:{actor_object_id:id, verb__in: ['ticket_comment', 'ticket_create']}
            }),
            invalidatesTags: (result) => result ? [{ type: 'Notification', id:result.id }, 'NotificationCount']:['NotificationCount'],
        }),
        setAsReadByNode: builder.mutation({
            query: (id) =>({
                url: 'unread/mark/',
                method: 'POST',
                withCredentials:true,
                data:{target_object_id:id, verb__in: ['observation', 'reparation', 'health_change']}
            }),
            invalidatesTags: (result) => result ? [{ type: 'Notification', id:result.id }, 'NotificationCount']:['NotificationCount'],
        }),
        getPreferencesById: builder.query({
            query: (params) => ({
                url: 'preferences/get/',
                method: 'GET',
                withCredentials:true,
                params:params
            }),
            providesTags: (result, error, arg) => {
                return (result && result.data.id !== undefined) ?
                    [{type:'NotificationPreference', id: result.data.id}] : ['NotificationPreference']
            }
        }),
        setPreferencesById: builder.mutation({
            query: ({id, ...params}) =>({
                url: 'preferences/set/',
                method: 'POST',
                withCredentials:true,
                data:{user_id: id, ...params}
            }),
            invalidatesTags: (result) => (result && result.data.id !== undefined) ?
                [{ type: 'NotificationPreference', id:result.data.id }] : ['NotificationPreference'],
        }),
        getTelegramChat: builder.query({
            query: (params) => ({
                url: 'telegram/chat/',
                method: 'GET',
                withCredentials:true,
                params:params
            }),
            providesTags: (result, error, arg) => {
                return (result && result.data.chat_id !== undefined) ?
                    [{type:'TelegramChat', chat_id: result.data.chat_id}] : ['TelegramChat']
            }
        }),
        sendTelegramChat: builder.mutation({
            query: ({node, chat_id, message, ...params}) =>({
                url: 'telegram/chat/',
                method: 'POST',
                withCredentials: true,
                data: {node, chat_id, message}
            }),
            invalidatesTags: (result) => (result && result.data.chat_id !== undefined) ?
                [{ type: 'TelegramMessage', id:result.data.chat_id }] : ['TelegramMessage'],
        }),
    }),
});

export const {
    endpoints,
    useGetReadQuery,
    useGetUnreadQuery,
    useGetUnreadCountByVerbQuery,
    useGetUnreadCountQuery,
    useGetReadCountQuery,
    useSetAsReadByIdMutation,
    useSetAsUnreadByIdMutation,
    useSetAsReadByTicketMutation,
    useSetAsReadByNodeMutation,
    useGetPreferencesByIdQuery,
    useSetPreferencesByIdMutation,
    useGetTelegramChatQuery,
    useSendTelegramChatMutation,
} = notificationsAPI;
export const notificationAPIReducer = notificationsAPI.reducer;
export const notificationAPIReducerPath = notificationsAPI.reducerPath;
export const notificationAPIReducerMiddleware = notificationsAPI.middleware;