import { createApi } from '@reduxjs/toolkit/query/react'
import {backend_server_address} from "../../backendServer";
import axios from "axios";


const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: '' }) =>
        async (axiosParams) => {
            try {
                axiosParams.url = baseUrl + axiosParams.url;
                const result = await axios.request(axiosParams);
                return { data: result.data }
            } catch (axiosError) {
                let err = axiosError;
                return {
                    error: { status: err.response ? err.response.status:undefined, data: err.response ? err.response.data:undefined },
                }
            }
        };

// Define a service using a base URL and expected endpoints
export const versionAPI = createApi({
    reducerPath: 'versionAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/app_version/'}),
    tagTypes: ['Version',],
    endpoints: (builder) => ({
        getLatestAppVersion: builder.query({
            query: ({app_name='maper-ui', version, build_date}) => ({
                url: 'latest/',
                method: 'GET',
                withCredentials:true,
                params:{app_name, version, build_date}
            }),
            providesTags: (result) => {
                return result ?
                    ['Version']
                    :[ 'Version' ]
            }
        }),
    }),
});

export const {
    useGetLatestAppVersionQuery,
} = versionAPI;
export const versionAPIReducer = versionAPI.reducer;
export const versionAPIReducerPath = versionAPI.reducerPath;
export const versionAPIReducerMiddleware = versionAPI.middleware;