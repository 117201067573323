import { createApi } from '@reduxjs/toolkit/query/react'
import { backend_server_address } from '../../../backendServer';
import { axiosBaseQuery } from '../../../store/utility';

export const internationalizationAPI = createApi({
    reducerPath: 'internationalizationAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/translations/'}),
    tagTypes: ['Translations'],
    endpoints: (builder) => ({
        getTranslations: builder.query({
            query: () => {
                return {
                    url: '',
                    method: 'GET',
                    withCredentials: true,
                }
            },
            providesTags: (result) => ['Translations'],
        }),
    }),
});

export const {
    endpoints,
    useGetTranslationsQuery,
} = internationalizationAPI;
export const internationalizationAPIReducer = internationalizationAPI.reducer;
export const internationalizationAPIReducerPath = internationalizationAPI.reducerPath;
export const internationalizationAPIReducerMiddleware = internationalizationAPI.middleware;