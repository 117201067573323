import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../../../../store/utility';
import { backend_server_address } from '../../../../../backendServer';

export const orientationAPI = createApi({
    reducerPath: 'orientationAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/model/'}),
    tagTypes: ['MeasPointOrientation', 'MachineOrientation'],
    endpoints: (builder) => ({
        getMeasPointOrientation: builder.query({
            query: (id) => {
                return {
                    url: `meas_point/orientation/${id}/desired/`,
                    method: 'GET',
                    withCredentials: true,
                }
            },
            providesTags: (result, error, id) => [{type: 'MeasPointOrientation', id}],
        }),
        getMachineOrientation: builder.query({
            query: (id) => {
                return {
                    url: `machine/orientation/${id}/desired/`,
                    method: 'GET',
                    withCredentials: true,
                }
            },
            providesTags: (result, error, id) => result ?
                [...Object.keys(result).map(x => ({ type:'MeasPointOrientation' as const, id: parseInt(x)})), {type: 'MachineOrientation', id}] :
                [{type: 'MachineOrientation', id}],
        }),
        resetMeasPointOrientation: builder.mutation({
            query: (id) => {
                return {
                    url: `meas_point/orientation/${id}/desired/`,
                    method: 'POST',
                    withCredentials: true,
                }
            },
            invalidatesTags: (result, error, id) => [{type: 'MeasPointOrientation', id}],
        }),
        resetMachineOrientation: builder.mutation({
            query: (id) => {
                return {
                    url: `machine/orientation/${id}/desired/`,
                    method: 'POST',
                    withCredentials: true,
                }
            },
            invalidatesTags: (result, error, id) => result ?
                [...Object.keys(result).map(x => ({type: 'MeasPointOrientation' as const, id: parseInt(x)})), {type: 'MachineOrientation', id}] :
                [{type: 'MachineOrientation', id}],
        }),
        changeThresholds: builder.mutation({
            query: ({id, data}) => {
                return {
                    url: `meas_point/orientation/${id}/thresholds/`,
                    method: 'PUT',
                    withCredentials: true,
                    data: data
                }
            },
            invalidatesTags: (result, error, id) => [{type: 'MeasPointOrientation', id}],
        }),
        deleteMeasPointOrientation: builder.mutation({
            query: (id) => {
                return {
                    url: `meas_point/orientation/${id}/desired/`,
                    method: 'DELETE',
                    withCredentials: true,
                }
            },
            invalidatesTags: (result, error, id) => [{type: 'MeasPointOrientation', id}],
        }),
        deleteMachineOrientation: builder.mutation({
            query: (id) => {
                return {
                    url: `machine/orientation/${id}/desired/`,
                    method: 'DELETE',
                    withCredentials: true,
                }
            },
            invalidatesTags: (result, error, id) => result ?
                [...Object.keys(result).map(x => ({type: 'MeasPointOrientation' as const, id: parseInt(x)})), {type: 'MachineOrientation', id}] :
                [{type: 'MachineOrientation', id}],
        }),
    }),
});

export const {
    endpoints,
    useGetMeasPointOrientationQuery,
    useGetMachineOrientationQuery,
    useResetMeasPointOrientationMutation,
    useResetMachineOrientationMutation,
    useChangeThresholdsMutation,
    useDeleteMeasPointOrientationMutation,
    useDeleteMachineOrientationMutation,
} = orientationAPI;
export const orientationAPIReducer = orientationAPI.reducer;
export const orientationAPIReducerPath = orientationAPI.reducerPath;
export const orientationAPIReducerMiddleware = orientationAPI.middleware;