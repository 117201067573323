import { createApi } from '@reduxjs/toolkit/query/react'
import {backend_server_address} from "../../../../../backendServer";
import axios from "axios";


const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: '' }) =>
        async (axiosParams) => {
            try {
                axiosParams.url = baseUrl + axiosParams.url;
                const result = await axios.request(axiosParams);
                return { data: result.data }
            } catch (axiosError) {
                let err = axiosError;
                return {
                    error: { status: err.response ? err.response.status:undefined, data: err.response ? err.response.data:undefined },
                }
            }
        };

// Define a service using a base URL and expected endpoints
export const maintenanceEventAPI = createApi({
    reducerPath: 'maintenanceEventAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/maintenance_module/'}),
    tagTypes: ['Reparation', 'Observation', 'MaintenanceEventSchema', 'ReparationCount', 'ObservationCount', 'MaintenanceEvent', 'Intervention'],
    endpoints: (builder) => ({
        getReparations: builder.query({
            query: (params) => ({
                url: 'reparation/',
                method: 'GET',
                withCredentials:true,
                params:params
            }),
            providesTags: (result) => {
                return result ?
                    [...result.map(pending => ({ type:'Reparation', id:pending.id})), 'Reparation']
                    :[ 'Reparation' ]
            }
        }),
        getObservations: builder.query({
            query: (params) => ({
                url: 'observation/',
                method: 'GET',
                withCredentials:true,
                params:params
            }),
            providesTags: (result) => {
                return result ?
                    [...result.map(pending => ({ type:'Observation', id:pending.id})), 'Observation']
                    :[ 'Observation' ]
            }
        }),
        getMaintenanceEventSchema: builder.query({
            query: (params) => ({
                url: 'maintenance_event/schema/',
                method: 'GET',
                withCredentials: true,
                params: params
            }),
            providesTags: (result, error, arg) => {
                return result ?
                    [{type: 'MaintenanceEventSchema'}]
                    : ['MaintenanceEventSchema']
            }
        }),
        getReparationById: builder.query({
            query: (id) => ({
                url: `reparation/${id}/`,
                method: 'GET',
                withCredentials:true,
            }),
            providesTags: (result) => {
                return result ? [{type: 'Reparation', id: result.id}]: ['Reparation'];
            },
        }),
        getObservationById: builder.query({
            query: (id) => ({
                url: `observation/${id}/`,
                method: 'GET',
                withCredentials:true,
            }),
            providesTags: (result) => {
                return result ? [{type: 'Observation', id: result.id}]: ['Observation'];
            },
        }),
        countReparations: builder.query({
            query: (node_id) => ({
                url: 'reparation/count/',
                method: 'GET',
                withCredentials:true,
                params: {node: node_id},
            }),
            providesTags: (result, error, arg) => {
                return arg ? [{type: 'ReparationCount', id: arg}]: ['ReparationCount'];
            },
        }),
        setClosedReparation: builder.mutation({
            query: ({id, value}) => {
                window.gtag('event', 'reparation_close', {
                    'event_category': 'maintenance_event',
                    'value': value,
                });
                return {
                    url: 'reparation/' + id + '/close/',
                    method: 'PATCH',
                    withCredentials: true,
                    data: {closed: value}
                }
            },
            invalidatesTags: (result) => result ?[{ type: 'Reparation', id:result.id }, 'ReparationCount']:['Reparation', 'ReparationCount'],
        }),
        setClosedObservation: builder.mutation({
            query: ({id, value}) => {
                window.gtag('event', 'observation_close', {
                    'event_category': 'maintenance_event',
                    'value': value,
                });
                return {
                    url: 'observation/' + id + '/close/',
                    method: 'PATCH',
                    withCredentials: true,
                    data: {closed: value}
                }
            },
            invalidatesTags: (result) => result ?[{ type: 'Observation', id:result.id }]:['Observation'],
        }),
        createReparation: builder.mutation({
            query: ({data}) => {
                window.gtag('event', 'reparation_create', {
                    'event_category': 'maintenance_event',
                });
                return {
                    url: 'reparation/',
                    method: 'POST',
                    withCredentials: true,
                    data: data
                }
            },
            invalidatesTags: (result) => ['Reparation', 'ReparationCount'],
        }),
        createObservation: builder.mutation({
            query: ({data}) => {
                window.gtag('event', 'observation_create', {
                    'event_category': 'maintenance_event',
                });
                return {
                    url: 'observation/',
                    method: 'POST',
                    withCredentials: true,
                    data: data
                }
            },
            invalidatesTags: (result) => ['Observation'],
        }),
        createGenericEvent: builder.mutation({
            query: ({data}) => {
                window.gtag('event', 'maintenance_event_create', {
                    'event_category': 'maintenance_event',
                });
                return {
                    url: 'maintenance_event/',
                    method: 'POST',
                    withCredentials: true,
                    data: data
                }
            },
            invalidatesTags: (result) => ['MaintenanceEvent'],
        }),
        getGenericEventById: builder.query({
            query: (id) => ({
                url: `maintenance_event/${id}/`,
                method: 'GET',
                withCredentials:true,
            }),
            providesTags: (result) => {
                return result ? [{type: 'MaintenanceEvent', id: result.id}]: ['MaintenanceEvent'];
            },
        }),
        createIntervention: builder.mutation({
            query: ({data}) => {
                window.gtag('event', 'intervention_create', {
                    'event_category': 'maintenance_event',
                });
                return {
                    url: 'intervention/',
                    method: 'POST',
                    withCredentials: true,
                    data: data
                }
            },
            invalidatesTags: (result) => ['Intervention'],
        }),
        getInterventionById: builder.query({
            query: (id) => ({
                url: 'intervention/' + id + '/',
                method: 'GET',
                withCredentials:true,
            }),
            providesTags: (result) => {
                return result ? [{type: 'Intervention', id: result.id}]: ['Intervention'];
            },
        }),
        editIntervention: builder.mutation({
            query: ({id, data}) => {
                return {
                    url: 'intervention/' + id + '/',
                    method: 'PATCH',
                    withCredentials: true,
                    // {end_date, data}
                    data: data
                }
            },
            invalidatesTags: (result) => {
                return result ? [{type: 'Intervention', id: result.id}] : ['Intervention'];
            },
        }),
        setClosedIntervention: builder.mutation({
            query: ({id, value}) => {
                window.gtag('event', 'intervention_close', {
                    'event_category': 'maintenance_event',
                    'value': value,
                });
                return {
                    url: 'intervention/' + id + '/close/',
                    method: 'PATCH',
                    withCredentials: true,
                    data: {closed: value}
                }
            },
            invalidatesTags: (result) => result ?[{ type: 'Intervention', id:result.id }]:['Intervention'],
        }),
    }),
});

export const {
    endpoints,
    useGetReparationsQuery,
    useGetObservationsQuery,
    useGetMaintenanceEventSchemaQuery,
    useGetReparationByIdQuery,
    useGetObservationByIdQuery,
    useCountReparationsQuery,
    useGetGenericEventByIdQuery,
    useCreateReparationMutation,
    useCreateObservationMutation,
    useCreateGenericEventMutation,
    useSetClosedReparationMutation,
    useSetClosedObservationMutation,
    useCreateInterventionMutation,
    useGetInterventionByIdQuery,
    useSetClosedInterventionMutation,
    useEditInterventionMutation
} = maintenanceEventAPI;
export const maintenanceEventAPIReducer = maintenanceEventAPI.reducer;
export const maintenanceEventAPIReducerPath = maintenanceEventAPI.reducerPath;
export const maintenanceEventAPIReducerMiddleware = maintenanceEventAPI.middleware;