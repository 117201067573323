import { createApi } from '@reduxjs/toolkit/query/react';
import {backend_server_address} from "../../../../../../backendServer";
import axios from "axios";


const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: '' }) =>
        async (axiosParams) => {
            try {
                axiosParams.url = baseUrl + axiosParams.url;
                const result = await axios.request(axiosParams);
                return { data: result.data }
            } catch (axiosError) {
                let err = axiosError;
                return {
                    error: { status: err.response ? err.response.status:undefined, data: err.response ? err.response.data:undefined },
                }
            }
        };

// Define a service using a base URL and expected endpoints
export const eventsAPI = createApi({
    reducerPath: 'eventsAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/event/'}),
    tagTypes: ['Event', 'EventSchema', 'LastEvent'],
    endpoints: (builder) => ({
        getEvents: builder.query({
            query: (params) => ({
                url: '',
                method: 'GET',
                withCredentials:true,
                //{node, min_date, max_date, add_alarm_events}
                params:params
            }),
            providesTags: (result) => {
                return result ?
                    [...result.map(event => ({ type:'Event', id:event.id})), 'Event']
                    :[ 'Event' ]
            }
        }),
        getEventSchema: builder.query({
                query: () => ({
                    url: 'schema/',
                    method: 'GET',
                    withCredentials: true,
                    // params: params
                }),
                providesTags: (result, error, arg) => {
                    return result ?
                        [{type: 'EventSchema'}]
                        : ['EventSchema']
                }
            }
        ),
        getEventById: builder.query({
            query: (id) => ({
                url: `${id}/`,
                method: 'GET',
                withCredentials:true,
            }),
            providesTags: (result) => {
                // console.log([{type: 'Pending', id: result.id}])
                return result ? [{type: 'Event', id: result.id}] : ['Event'];
            },
        }),
        getLastEvents: builder.query({
            query: (params) => ({
                url: 'last/',
                method: 'GET',
                withCredentials:true,
                //{node, type}
                params:params
            }),
            providesTags: (result) => {
                return result ?
                    [{ type:'LastEvent', id:'LastEvent'}, 'LastEvent']
                    :[ 'LastEvent' ]
            }
        }),
        editEvent: builder.mutation({
            query: ({id, data}) =>({
                url: '' + id + '/',
                method: 'PATCH',
                withCredentials:true,
                data:data
            }),
            invalidatesTags: (result) => result ? [{ type: 'Event', id:result.id }]:['Event'],
        }),
        createEvent: builder.mutation({
            query: ({data}) =>({
                url: '',
                method: 'POST',
                withCredentials:true,
                data:data
            }),
            invalidatesTags: (result) => ['Event'],
        }),
        deleteEvent: builder.mutation({
            query: ({id, data}) =>({
                url: '' + id + '/',
                method: 'DELETE',
                withCredentials:true,
                data:data
            }),
            invalidatesTags: (result) => ['Event'], //[{ type: 'Event', id:result.id }],
        }),
        commentEvent: builder.mutation({
            query: ({id,comment}) => {
                window.gtag('event', 'event_comment', {
                    'event_category': 'event',
                });
                return {
                    url: '' + id + '/comment/',
                    method: 'PUT',
                    withCredentials: true,
                    data: {comment}
                }
            },
            invalidatesTags: (result) => result ? [{ type: 'Event', id:result.id }]:['Event'],
        }),
        getNextEvents: builder.query({
            query: ({id, ...params}) => ({
                url: '' + id + '/next/',
                method: 'GET',
                withCredentials:true,
                //{node, type, etc}
                params:params
            }),
            providesTags: (result) => {
                return result ?
                    [...result.map(event => ({ type:'Event', id:event.id})), 'Event']
                    :[ 'Event' ]
            }
        }),
    }),
});