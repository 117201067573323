import * as actionTypes from "./AlarmsActions";
import {updateObject} from "../../utility";

const  initialState = {
    alarms:{}
};

const alarmsReducerUpdater = (state, action) => {
    return updateObject(state,{
        ...action,
    });
};

const alarmsReducer = (state=initialState, action) => {
    if (action.type in actionTypes){
        return alarmsReducerUpdater(state,action);
    }
    return state;
};

export default alarmsReducer;