import {eventsAPI} from "./eventsDuck";
import {causesAPI} from "./causesDuck";

export const {
    endpoints,
    useGetEventsQuery,
    useGetEventSchemaQuery,
    useGetEventByIdQuery,
    useGetLastEventsQuery,
    useEditEventMutation,
    useCreateEventMutation,
    useDeleteEventMutation,
    useCommentEventMutation,
    useGetNextEventsQuery,
} = eventsAPI;
export const eventsAPIReducer = eventsAPI.reducer;
export const eventsAPIReducerPath = eventsAPI.reducerPath;
export const eventsAPIReducerMiddleware = eventsAPI.middleware;

export const {
    useListCausesQuery,
    useAddEventCauseMutation,
    useDeleteEventCauseMutation,
} = causesAPI;
export const causesAPIReducer = causesAPI.reducer;
export const causesAPIReducerPath = causesAPI.reducerPath;
export const causesAPIReducerMiddleware = causesAPI.middleware;