import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../../../../store/utility';
import { backend_server_address } from '../../../../../backendServer';

export const exportAPI = createApi({
    reducerPath: 'exportAPI',
    baseQuery: axiosBaseQuery({ baseUrl: `${backend_server_address}/api/export/`}),
    endpoints: (builder) => ({
        exportAbi: builder.query({
            query: () => {
                return {
                    url: 'abi/',
                    method: 'GET',
                    withCredentials: true,
                    responseType: 'blob',
                }
            },
        }),
    }),
});

export const {
    endpoints,
    useExportAbiQuery,
} = exportAPI;
export const exportAPIReducer = exportAPI.reducer;
export const exportAPIReducerPath = exportAPI.reducerPath;
export const exportAPIReducerMiddleware = exportAPI.middleware;