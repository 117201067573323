import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, matchPath} from 'react-router-dom';
import './assets/base.scss';
import Main from './main';
import { Provider } from 'react-redux';
import configureAppStore from "./store/configureStore";
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import {routes} from "./routes";
import { registerLicense } from '@syncfusion/ej2-base';
import { hideSpecificWarnings } from './dev';

hideSpecificWarnings()

registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY || '');

const store = configureAppStore();

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

if(process.env.REACT_APP_ENABLE_SENTRY === 'true')
    Sentry.init({
        dsn: "https://ca5324a266d5420998ab8350b8c7e26d@o977311.ingest.sentry.io/5933827",
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
        release: process.env.REACT_APP_VERSION,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(window.history, routes, matchPath),
            }),
        ],
        whitelistUrls: [
            /(?:[a-z]+\.)?mapertech\.com/
        ],
        ignoreErrors:[
            'ResizeObserver loop limit exceeded'
        ],
        tracesSampleRate: 1.0,
    });

const renderApp = Component => {
    root.render(
        <Provider store={store}>
            <BrowserRouter>
                <Component />
            </BrowserRouter>
        </Provider>
    );
};

renderApp(Main);

if (module.hot) {
    module.hot.accept('./main', () => {
        const NextApp = require('./main').default;
        renderApp(NextApp);
    });
}
serviceWorker.unregister();

export default store;