import {createSlice} from '@reduxjs/toolkit'

const initialState = {
};

export const dateSelectorSlice = createSlice({
    name: 'dateSelector',
    initialState,
    reducers: {
        initKeyR: (state, action) => {
            const {key} = action.payload;
            state[key] = {
                start:undefined,
                end:undefined
            };
        },
        setDatesR: (state, action) => {
            const {key, start, end} = action.payload;
            state[key] = {
                start:start,
                end:end
            };
        },
    },
});

// Action creators are generated for each case reducer function
const { setDatesR } = dateSelectorSlice.actions;

export const setDates = (key, start, end) => setDatesR({key, start, end});
const dateRange = dateSelectorSlice.reducer;

export default dateRange;

export const selectStartDateKey = (key)=> (state) => (state.dateRange && state.dateRange[key]) ? state.dateRange[key].start:undefined;
export const selectEndDateKey = (key)=> (state) => (state.dateRange && state.dateRange[key]) ? state.dateRange[key].end:undefined;
