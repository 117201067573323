import { createApi } from '@reduxjs/toolkit/query/react';
import {backend_server_address} from "../../../../../backendServer";
import axios from "axios";


const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: '' }) =>
        async (axiosParams) => {
            try {
                axiosParams.url = baseUrl + axiosParams.url;
                const result = await axios.request(axiosParams);
                return { data: result.data }
            } catch (axiosError) {
                let err = axiosError;
                return {
                    error: { status: err.response ? err.response.status:undefined, data: err.response ? err.response.data:undefined },
                }
            }
        };

// Define a service using a base URL and expected endpoints
export const problemsAPI = createApi({
    reducerPath: 'problemsAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/'}),
    tagTypes: ['Problem', 'ProblemType'],
    endpoints: (builder) => ({
        getProblemTypes: builder.query({
            query: (params) => ({
                url: 'problem_type/',
                method: 'GET',
                withCredentials:true,
                //{node, min_date, max_date, add_alarm_events}
                // params:params
            }),
            providesTags: (result) => {
                return result ?
                    [...result.map(problem_type => ({ type:'ProblemType', id:problem_type.id})), 'ProblemType']
                    :[ 'ProblemType' ]
            }
        }),
        getProblems: builder.query({
            query: (params) => ({
                url: 'problem/',
                method: 'GET',
                withCredentials:true,
                //{node, end_date}
                params:params
            }),
            providesTags: (result) => {
                return result ?
                    [...result.map(problem => ({ type:'Problem', id:problem.id})), 'Problem']
                    :[ 'Problem' ]
            }
        }),
        // getEventById: builder.query({
        //     query: (id) => ({
        //         url: '' + id,
        //         method: 'GET',
        //         withCredentials:true,
        //     }),
        //     providesTags: (result) => {
        //         // console.log([{type: 'Pending', id: result.id}])
        //         return result ? [{type: 'Event', id: result.id}] : ['Event'];
        //     },
        // }),
        editProblem: builder.mutation({
            query: ({id, data}) =>({
                url: 'problem/' + id + '/',
                method: 'PATCH',
                withCredentials:true,
                data:data
            }),
            invalidatesTags: (result) => result ? [{ type: 'Problem', id:result.id }]:['Problem'],
        }),
        createProblem: builder.mutation({
            query: ({data}) =>({
                url: 'problem/',
                method: 'POST',
                withCredentials:true,
                data:data
            }),
            invalidatesTags: (result) => ['Problem'],
        }),
        // deleteEvent: builder.mutation({
        //     query: ({id, data}) =>({
        //         url: '' + id + '/',
        //         method: 'DELETE',
        //         withCredentials:true,
        //         data:data
        //     }),
        //     invalidatesTags: (result) => ['Event'], //[{ type: 'Event', id:result.id }],
        // }),
        // commentEvent: builder.mutation({
        //     query: ({id,comment}) => {
        //         window.gtag('event', 'event_comment', {
        //             'event_category': 'event',
        //         });
        //         return {
        //             url: '' + id + '/comment/',
        //             method: 'PUT',
        //             withCredentials: true,
        //             data: {comment}
        //         }
        //     },
        //     invalidatesTags: (result) => result ? [{ type: 'Event', id:result.id }]:['Event'],
        // }),
        // getNextEvents: builder.query({
        //     query: ({id, ...params}) => ({
        //         url: '' + id + '/next/',
        //         method: 'GET',
        //         withCredentials:true,
        //         //{node, type, etc}
        //         params:params
        //     }),
        //     providesTags: (result) => {
        //         return result ?
        //             [...result.map(event => ({ type:'Event', id:event.id})), 'Event']
        //             :[ 'Event' ]
        //     }
        // }),
    }),
});