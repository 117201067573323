import * as actionTypes from "./AnalyticsActions";
import {updateObject} from "../../utility";

const  initialState = {
    alarmsCount: {},
    machineHealthCount: {},
};

const analyticsReducerUpdater = (state, action) => {
    return updateObject(state,{
        ...action,
    });
};

const analyticsReducer = (state=initialState, action) => {
    if (action.type in actionTypes){
        return analyticsReducerUpdater(state,action);
    }
    return state;
};

export default analyticsReducer;