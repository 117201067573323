import * as actionTypes from "./HardwareActions";
import {updateObject} from "../../utility";

const  initialState = {
    hardwareList:[],
    hardwareMap:{},
    hardware_link_list:[],
};

const hardwareReducerUpdater = (state, action) => {
    return updateObject(state,{
        ...action,
    });
};

const hardwareReducer = (state=initialState, action) => {
    if (action.type in actionTypes){
        return hardwareReducerUpdater(state,action);
    }
    return state;
};

export default hardwareReducer;