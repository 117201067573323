export const ALARMS_CREATE_START = 'ALARMS_CREATE_START';
export const ALARMS_CREATE_SUCCESS = 'ALARMS_CREATE_SUCCESS';
export const ALARMS_CREATE_FAIL = 'ALARMS_CREATE_FAIL';

export const ALARMS_EDIT_START = 'ALARMS_EDIT_START';
export const ALARMS_EDIT_SUCCESS = 'ALARMS_EDIT_SUCCESS';
export const ALARMS_EDIT_FAIL = 'ALARMS_EDIT_FAIL';

export const ALARMS_DELETE_START = 'ALARMS_DELETE_START';
export const ALARMS_DELETE_SUCCESS = 'ALARMS_DELETE_SUCCESS';
export const ALARMS_DELETE_FAIL = 'ALARMS_DELETE_FAIL';

export const ALARMS_GET_NODE_START = 'ALARMS_GET_NODE_START';
export const ALARMS_GET_NODE_SUCCESS = 'ALARMS_GET_NODE_SUCCESS';
export const ALARMS_GET_NODE_FAIL = 'ALARMS_GET_NODE_FAIL';

export const ALARMS_GET_EVENTS_START = 'ALARMS_GET_EVENTS_START';
export const ALARMS_GET_EVENTS_SUCCESS = 'ALARMS_GET_EVENTS_SUCCESS';
export const ALARMS_GET_EVENTS_FAIL = 'ALARMS_GET_EVENTS_FAIL';

export const ALARMS_SET_CURRENT_ALARMS = 'ALARMS_SET_CURRENT_ALARMS';

export const ALARMS_CLEAR_ALARMS = 'ALARMS_CLEAR_ALARMS';