export async function importRetryReload(importFn, retries = 3, interval = 1000) {
    try {
        return await importFn();
    } catch (error) {
        if (retries) {
            await wait(interval);
            console.log('Chunk failed retrying '+ retries + ' more times.');
            return await importRetryReload(importFn, retries - 1, interval);
        } else {
            window.location.replace(window.location.href)
        }
    }
}

function wait(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}