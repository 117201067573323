import { createApi } from '@reduxjs/toolkit/query/react'
import {backend_server_address} from "../../../../../backendServer";
import axios from "axios";


const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: '' }) =>
        async (axiosParams) => {
            try {
                axiosParams.url = baseUrl + axiosParams.url;
                const result = await axios.request(axiosParams);
                return { data: result.data }
            } catch (axiosError) {
                let err = axiosError;
                return {
                    error: { status: err.response ? err.response.status:undefined, data: err.response ? err.response.data:undefined },
                }
            }
        };

// Define a service using a base URL and expected endpoints
export const pendingsAPI = createApi({
    reducerPath: 'pendingsAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/maintenance_module/ticket/'}),
    tagTypes: ['Pending', 'PendingSchema', 'PendingCount'],
    endpoints: (builder) => ({
        getPendings: builder.query({
            query: (params) => ({
                url: '',
                method: 'GET',
                withCredentials:true,
                params:params
            }),
            providesTags: (result) => {
                return result ?
                    [...result.map(pending => ({ type:'Pending', id:pending.id})), 'Pending']
                    :[ 'Pending' ]
            }
        }),
        getPendingSchema: builder.query({
                query: (params) => ({
                    url: 'schema/',
                    method: 'GET',
                    withCredentials: true,
                    params: params
                }),
                providesTags: (result, error, arg) => {
                    return result ?
                        [{type: 'PendingSchema', id: arg.node_id}]
                        : ['PendingSchema']
                }
            }
        ),
        getPendingById: builder.query({
            query: (id) => ({
                url: `${id}/`,
                method: 'GET',
                withCredentials:true,
            }),
            providesTags: (result) => {
                // console.log([{type: 'Pending', id: result.id}])
                return result ? [{type: 'Pending', id: result.id}]: ['Pending'];
            },
        }),
        countPendingsById: builder.query({
            query: ({id, ...params}) => ({
                url: '' + id + '/count/',
                method: 'GET',
                withCredentials:true,
                params: params,
            }),
            providesTags: (result) => {
                return result ? [{type: 'PendingCount', id: result.id}]: ['PendingCount'];
            },
        }),
        setAcceptedPending: builder.mutation({
            query: ({id, comment=undefined, params={}}) =>{
                window.gtag('event', 'ticket_accept', {
                    'event_category': 'ticket',
                });
                return {
                    url: '' + id + '/accepted/',
                    method: 'PATCH',
                    withCredentials:true,
                    data:{accepted:true, comment:comment, ...params}
                }
            },
            invalidatesTags: (result) => result ? [{ type: 'Pending', id:result.id }, 'PendingCount']:['Pending'],
        }),
        setDonePending: builder.mutation({
            query: ({id, value, comment=undefined}) => {
                window.gtag('event', 'ticket_done', {
                    'event_category': 'ticket',
                    'value': value
                });
                return {
                    url: '' + id + '/done/',
                    method: 'PATCH',
                    withCredentials: true,
                    data: {done: value, ...(comment !== undefined ? {comment} : {})}
                }
            },
            invalidatesTags: (result) => {
                // console.log([{type: 'Pending', id: result.id}])
                return result ? [{type: 'Pending', id: result.id}, 'PendingCount']:['Pending'];
            },
        }),
        setClosedPending: builder.mutation({
            query: ({id, value, comment=undefined}) => {
                window.gtag('event', 'ticket_close', {
                    'event_category': 'ticket',
                    'value': value
                });
                return {
                    url: '' + id + '/closed/',
                    method: 'PATCH',
                    withCredentials: true,
                    data: {closed: value, ...(comment !== undefined ? {comment} : {})}
                }
            },
            invalidatesTags: (result) => result ?[{ type: 'Pending', id:result.id }, 'PendingCount']:['Pending'],
        }),
        commentPending: builder.mutation({
            query: ({id,comment}) => {
                window.gtag('event', 'ticket_comment', {
                    'event_category': 'ticket',
                });
                return {
                    url: '' + id + '/comment/',
                    method: 'PUT',
                    withCredentials: true,
                    data: {comment}
                }
            },
            invalidatesTags: (result) => result ? [{ type: 'Pending', id:result.id }]: ['Pending'],
        }),
        editPending: builder.mutation({
            query: ({id, data}) => {
                window.gtag('event', 'ticket_edit', {
                    'event_category': 'ticket',
                });
                return {
                    url: '' + id + '/',
                    method: 'PATCH',
                    withCredentials: true,
                    data: data
                }
            },
            invalidatesTags: (result) => result ? [{ type: 'Pending', id:result.id }]:['Pending'],
        }),
        createPending: builder.mutation({
            query: ({data}) => {
                window.gtag('event', 'ticket_create', {
                    'event_category': 'ticket',
                });
                return {
                    url: '',
                    method: 'POST',
                    withCredentials: true,
                    data: data
                }
            },
            invalidatesTags: (result) => ['Pending'],
        }),
    }),
});

export const {
    endpoints,
    useGetPendingsQuery,
    useGetPendingSchemaQuery,
    useGetPendingByIdQuery,
    useCountPendingsByIdQuery,
    useSetDonePendingMutation,
    useSetAcceptedPendingMutation,
    useSetClosedPendingMutation,
    useCommentPendingMutation,
    useEditPendingMutation,
    useCreatePendingMutation,
} = pendingsAPI;
export const pendingAPIReducer = pendingsAPI.reducer;
export const pendingAPIReducerPath = pendingsAPI.reducerPath;
export const pendingAPIReducerMiddleware = pendingsAPI.middleware;