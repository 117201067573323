import { createApi } from '@reduxjs/toolkit/query/react';
import {backend_server_address} from "../../../../../backendServer";
import axios from "axios";


const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: '' }) =>
        async (axiosParams) => {
            try {
                axiosParams.url = baseUrl + axiosParams.url;
                const result = await axios.request(axiosParams);
                return { data: result.data }
            } catch (axiosError) {
                let err = axiosError;
                return {
                    error: { status: err.response ? err.response.status:undefined, data: err.response ? err.response.data:undefined },
                }
            }
        };

// Define a service using a base URL and expected endpoints
export const featuresAPI = createApi({
    reducerPath: 'featuresAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/machine_learning/feature/'}),
    tagTypes: ['Feature', 'FeatureMeasurements'],
    endpoints: (builder) => ({
        getFeatures: builder.query({
            query: (params) => ({
                url: '',
                method: 'GET',
                withCredentials:true,
                //{node, date}
                params:params
            }),
            providesTags: (results) => {
                return ['FeatureMeasurements']
                // return results ?
                //     [...results.map(feature => ({ type:'Feature', id:feature.id})), 'Feature']
                //     :[ 'Feature' ]
            }
        }),
        getFeatureById: builder.query({
            query: (id) => ({
                url: `${id}/`,
                method: 'GET',
                withCredentials:true,
            }),
            providesTags: (result) => {
                return result ? [{type: 'Feature', id: result.id}] : ['Feature'];
            },
        }),
        // getLastDiagnostics: builder.query({
        //     query: (params) => ({
        //         url: 'last/',
        //         method: 'GET',
        //         withCredentials:true,
        //         //{node}
        //         params:params
        //     }),
        //     providesTags: (result) => {
        //         return result ?
        //             [{ type:'LastDiagnostic', id:'LastDiagnostic'}, 'LastDiagnostic']
        //             :[ 'LastDiagnostic' ]
        //     }
        // }),
    }),
});