import { createApi } from '@reduxjs/toolkit/query/react'
import {backend_server_address} from "../../../../../backendServer";
import axios from "axios";


const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: '' }) =>
        async (axiosParams) => {
            try {
                axiosParams.url = baseUrl + axiosParams.url;
                const result = await axios.request(axiosParams);
                return { data: result.data }
            } catch (axiosError) {
                let err = axiosError;
                return {
                    error: { status: err.response ? err.response.status:undefined, data: err.response ? err.response.data:undefined },
                }
            }
        };

// Define a service using a base URL and expected endpoints
export const analyticsAPI = createApi({
    reducerPath: 'analyticsAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/analytics/'}),
    tagTypes: ['Analytics', 'MachineHealthHistory', 'MachineHealthCount'],
    endpoints: (builder) => ({
        setTicketAutomation: builder.mutation({
            query: (data) =>({
                url: 'automation/ticket/',
                method: 'PUT',
                withCredentials:true,
                //node_id, health, enabled, name, description
                data: data
            }),
            invalidatesTags: (result) => result ? [{ type: 'Analytics'}]: ['Analytics'],
        }),
        getMachineHealthHistory: builder.query({
            query: (params) => ({
                url: 'count/machine_health_history/',
                method: 'GET',
                withCredentials:true,
                // {node_id, min_date, max_date}
                params: params,
            }),
            providesTags: (result, error, arg) => {
                return ['MachineHealthHistory'];
                // return result ? [{type: 'MachineHealthHistory', id: arg.node_id}]: ['MachineHealthHistory'];
            },
        }),
        getMachineHealthCount: builder.query({
            query: (params) => ({
                url: 'count/machine_health/',
                method: 'GET',
                withCredentials:true,
                // {node_id, ids}
                params: params,
            }),
            providesTags: (result, error, arg) => {
                return ['MachineHealthCount'];
                // return result ? [{type: 'MachineHealthCount', id: arg.node_id}]: ['MachineHealthCount'];
            },
        }),
    }),
});

export const {
    endpoints,
    useSetTicketAutomationMutation,
    useGetMachineHealthHistoryQuery,
    useGetMachineHealthCountQuery,
} = analyticsAPI;
export const analyticsAPIReducer = analyticsAPI.reducer;
export const analyticsAPIReducerPath = analyticsAPI.reducerPath;
export const analyticsAPIReducerMiddleware = analyticsAPI.middleware;