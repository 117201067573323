import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { AxiosBaseQueryResponse } from "./types";

//Take oldObject fields and update it with new properties
export const updateObject = (oldObject: any, updatedProperties: any) => {
    return{
        ...oldObject,
        ...updatedProperties
    }
}

export const axiosBaseQuery = ({ baseUrl } : { baseUrl?: string } = {}): ((axiosParams: AxiosRequestConfig) => Promise<AxiosBaseQueryResponse>) => {
    return async (axiosParams: AxiosRequestConfig) => {
        try {
            axiosParams.url = (baseUrl || '') + axiosParams.url;
            const result: AxiosResponse = await axios.request(axiosParams);
            return { data: result.data }
        } catch (axiosError) {
            const err = axiosError as AxiosError;
            return {
                error: { status: err.response ? err.response.status:undefined, data: err.response ? err.response.data:undefined },
            }
        }
    };
}