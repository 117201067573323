import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import esTranslations from './locales/es/translation.json';
import enTranslations from './locales/en/translation.json';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            es: {
                translation: esTranslations
            },
            en: {
                translation: enTranslations
            }
        },
        fallbackLng: "es",
    });

export const updateLanguage = (language: string, translations: any) => {
    if (language) {
        if (translations && translations[language] && translations[language]['translation']) {
            i18n.addResourceBundle(language.substring(0, 2), 'translation', translations[language]['translation'], true, true);
        }
        if (!language.toLowerCase().includes(i18n.language.toLowerCase())) {
            i18n.changeLanguage(language);
        }
    }
}

export default i18n;