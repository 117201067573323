export const backend_server_info = {
    protocol:'https://',
    hostname:'backend.mapertech.com',
    port:'80'
};

export const backend_testing_info = {
    protocol:'https://',
    hostname:'testing.mapertech.com',
    port:'80'
};

export const backend_local_info = {
    protocol:'http://',
    hostname:'localhost:8000',
    port:'8000'
};
// export const backend_server_address = backend_server_info.protocol+backend_server_info.hostname;//+':'+backend_server_info.port;
// export const backend_server_address = backend_local_info.protocol+backend_local_info.hostname;//+':'+backend_server_info.port;
// export const backend_server_address = backend_testing_info.protocol+backend_testing_info.hostname;
export let backend_server_address = process.env.REACT_APP_BACKEND_BASE_URL;

export const set_backend_server_address = (new_address) => {
    backend_server_address = new_address;
}

