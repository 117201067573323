import { createApi } from '@reduxjs/toolkit/query/react';
import {backend_server_address} from "../../../../../backendServer";
import axios from "axios";


const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: '' }) =>
        async (axiosParams) => {
            try {
                axiosParams.url = baseUrl + axiosParams.url;
                const result = await axios.request(axiosParams);
                return { data: result.data }
            } catch (axiosError) {
                let err = axiosError;
                return {
                    error: { status: err.response ? err.response.status:undefined, data: err.response ? err.response.data:undefined },
                }
            }
        };

// Define a service using a base URL and expected endpoints
export const evidenceAPI = createApi({
    reducerPath: 'evidenceAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/evidence/'}),
    tagTypes: ['Evidence'],
    endpoints: (builder) => ({
        getEvidence: builder.query({
            query: (params) => ({
                url: 'problem/',
                method: 'GET',
                withCredentials:true,
                //{node, start_date, end_date}
                params:params
            }),
            providesTags: (result) => {
                return result ?
                    [...result.map(evidence => ({ type:'Evidence', id:evidence.id})), 'Evidence']
                    :[ 'Evidence' ]
            }
        }),
        getEvidenceById: builder.query({
            query: (id) => ({
                url: `${id}/`,
                method: 'GET',
                withCredentials:true,
            }),
            providesTags: (result) => {
                return result ? [{type: 'Evidence', id: result.id}] : ['Evidence'];
            },
        }),
        // editProblem: builder.mutation({
        //     query: ({id, data}) =>({
        //         url: 'problem/' + id + '/',
        //         method: 'PATCH',
        //         withCredentials:true,
        //         data:data
        //     }),
        //     invalidatesTags: (result) => result ? [{ type: 'Problem', id:result.id }]:['Problem'],
        // }),
        createEvidence: builder.mutation({
            query: ({data}) =>({
                url: '',
                method: 'POST',
                withCredentials:true,
                // {node, start_date, end_date, measurement_ids, checked}
                data:data
            }),
            invalidatesTags: (result) => ['Evidence'],
        }),
        // deleteEvent: builder.mutation({
        //     query: ({id, data}) =>({
        //         url: '' + id + '/',
        //         method: 'DELETE',
        //         withCredentials:true,
        //         data:data
        //     }),
        //     invalidatesTags: (result) => ['Event'], //[{ type: 'Event', id:result.id }],
        // }),
        // commentEvent: builder.mutation({
        //     query: ({id,comment}) => {
        //         window.gtag('event', 'event_comment', {
        //             'event_category': 'event',
        //         });
        //         return {
        //             url: '' + id + '/comment/',
        //             method: 'PUT',
        //             withCredentials: true,
        //             data: {comment}
        //         }
        //     },
        //     invalidatesTags: (result) => result ? [{ type: 'Event', id:result.id }]:['Event'],
        // }),
    }),
});