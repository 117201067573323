import { createApi } from '@reduxjs/toolkit/query/react';
import {backend_server_address} from "../../../../../../backendServer";
import axios from "axios";


const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: '' }) =>
        async (axiosParams) => {
            try {
                axiosParams.url = baseUrl + axiosParams.url;
                const result = await axios.request(axiosParams);
                return { data: result.data }
            } catch (axiosError) {
                let err = axiosError;
                return {
                    error: { status: err.response ? err.response.status:undefined, data: err.response ? err.response.data:undefined },
                }
            }
        };

// Define a service using a base URL and expected endpoints
export const causesAPI = createApi({
    reducerPath: 'causesAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/cause/'}),
    tagTypes: ['Cause'],
    endpoints: (builder) => ({
        listCauses: builder.query({
            query: (params) => ({
                url: '',
                method: 'GET',
                withCredentials:true,
                //{node, type}
                params:params
            }),
            providesTags: (result) => {
                return result ?
                    [...result.map(cause => ({ type:'Cause', id:cause.id})), 'Cause']
                    :[ 'Cause' ]
            }
        }),
        addEventCause: builder.mutation({
            query: ({id, data}) =>({
                url: '' + id + '/add_event/',
                method: 'POST',
                withCredentials:true,
                data: {event_id: data}
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Event', id:arg.data}, 'Cause'],
        }),
        deleteEventCause: builder.mutation({
            query: ({id, data}) =>({
                url: '' + id + '/remove_event/',
                method: 'POST',
                withCredentials:true,
                data: {event_id: data}
            }),
            invalidatesTags: (result, error, arg) => [{type: 'Event', id:arg.data}, 'Cause'],
        }),
    }),
});