import * as actionTypes from "./EventsActions";
import {updateObject} from "../../utility";

const  initialState = {
    events:[],
    eventSchema: {},
    causes: [],
};

const eventsReducerUpdater = (state, action) => {
    return updateObject(state,{
        ...action,
    });
};

const eventsReducer = (state=initialState, action) => {
    if (action.type in actionTypes){
        return eventsReducerUpdater(state,action);
    }
    return state;
};

export default eventsReducer;