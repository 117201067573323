import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import {backend_server_address} from "../../../../../backendServer";

const downloadData = createAsyncThunk(
    'configuration/downloadData',
    async (data, {rejectWithValue}) => {
        return axios.get(backend_server_address +'/api/equipment_nodes/configuration/bulk/', {
            withCredentials: true,
            responseType: 'blob',
            params: data
        })
            .then(res => {
                return res;
            }).catch(error => {
                return rejectWithValue(error.response.data);
            })
    }
);

export const downloadDataSlice = createSlice({
    name: 'dataDownload',
    initialState: {error: null, loading: 'idle'},
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(downloadData.pending, (state, action) => {
                // console.log('pendings download data', {state, action});
                if (state.loading === 'idle') {
                    state.loading = 'pending';
                }
            })
            .addCase(downloadData.fulfilled, (state, action) => {
                // console.log('master data download slice, fullfilled case', {state, action});
                if (state.loading === 'pending') {
                    // window.gtag('event', 'report_download', {
                    //     'event_label': action.meta.arg.reportName,
                    //     'event_category': 'report',
                    // });
                    state.loading = 'idle';
                    state.error = null;

                    let res = action.payload;
                    let url = window.URL.createObjectURL(res.data);
                    url = window.URL.createObjectURL(
                        new Blob([res.data], { type: res.data.type })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute('download', `${action.meta.arg.type}.xlsx`);

                    document.body.appendChild(link);

                    link.click();
                }
            })
            .addCase(downloadData.rejected, (state, action) => {
                if (state.loading === 'pending') {
                    state.loading = 'idle';
                    state.error = action.error;
                }
            })

    }
});

// Action creators are generated for each case reducer function

export const downloadMasterData = (params) => downloadData(params);
export const selectLoadingState = () => (state) => state.dataDownload ? state.dataDownload.loading === 'pending':false;
export const selectDownloadError = () => (state) => state.dataDownload ? state.dataDownload.error:undefined;
const dataDownload = downloadDataSlice.reducer;
export default dataDownload;
