import * as actionTypes from "./UsersActions";
import {updateObject} from "../../utility";

const  initialState = {
    username:'',
    userList: [],
    loading: false,
};

const usersReducerUpdater = (state, action) => {
    return updateObject(state,{
        ...action,
    });
};

const usersReducer = (state=initialState, action) => {
    if (action.type in actionTypes){
        return usersReducerUpdater(state,action);
    }
    return state;
};

export default usersReducer;