import * as actionTypes from "./TreeActions";
import {updateObject} from "../../utility";

const  initialState = {
    deleteNodeSuccess:0,
    treeMap:{},
};

const nodeTreeReducerUpdater = (state, action) => {
    return updateObject(state,{
        ...action,
    });
};

const nodeTreeReducer = (state=initialState, action) => {
    if (action.type in actionTypes){
        return nodeTreeReducerUpdater(state,action);
    }
    return state;
};

export default nodeTreeReducer;