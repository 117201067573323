import {createSlice} from '@reduxjs/toolkit'
import { RootState } from '../../../../../../store/types';
import { DateGroupState } from '../types';

const initialState = {};

export const dateGroupSelectorSlice = createSlice({
    name: 'dateGroupSelector',
    initialState,
    reducers: {
        setDateGroupR: (state: DateGroupState, action) => {
            const {key, value} = action.payload;
            state[key] = {
                option: value,
            };
        },
    },
});

const { setDateGroupR } = dateGroupSelectorSlice.actions;

export const setDateGroup = (key: string, value: string) => setDateGroupR({key, value});
const dateGroup = dateGroupSelectorSlice.reducer;

export default dateGroup;

export const selectDateGroupKey = (key: string) => (state: RootState) => (state.dateGroup && state.dateGroup[key]) ? state.dateGroup[key] : undefined;
