import { createApi } from '@reduxjs/toolkit/query/react'
import {backend_server_address} from "../../../../../backendServer";
import axios from "axios";

const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: '' }) =>
        async (axiosParams) => {
            try {
                axiosParams.url = baseUrl + axiosParams.url;
                const result = await axios.request(axiosParams);
                return { data: result.data }
            } catch (axiosError) {
                let err = axiosError;
                return {
                    error: { status: err.response ? err.response.status:undefined, data: err.response ? err.response.data:undefined },
                }
            }
        };

// Define a service using a base URL and expected endpoints
export const reportsAPI = createApi({
    reducerPath: 'reportsApi',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/report/'}),
    tagTypes: ['Report', 'ReportSchema'],
    endpoints: (builder) => ({
        getReports: builder.query({
            query: (params) => ({
                url: '',
                method: 'GET',
                withCredentials:true,
                params:params
            }),
            providesTags: (query) => {
                return query ?
                    [...query.results.map(report => ({ type:'Report', id:report.id})), 'Report']
                    :[ 'Report' ]
            }
        }),
        getReportById: builder.query({
            query: (id) => ({
                url: `${id}/`,
                method: 'GET',
                withCredentials:true,
            }),
            providesTags: (result) => {
                return result ? [{type: 'Report', id: result.id}]: ['Report'];
            },
        }),
        createReport: builder.mutation({
            query: (data) => {
                window.gtag('event', 'report_create', {
                    'event_category': 'report',
                });
                return {
                    url: '',
                    method: 'POST',
                    withCredentials: true,
                    data: data
                }
            },
            invalidatesTags: (result) => ['Report'],
        }),
        editReport: builder.mutation({
            query: ({id, data}) => {
                return {
                    url: id +'/',
                    method: 'PATCH',
                    withCredentials: true,
                    data: data
                }
            },
            invalidatesTags: (result) => ['Report'],
        }),
        getReportSchema: builder.query({
            query: () => ({
                url: 'schema/',
                method: 'GET',
                withCredentials:true,
            }),
            providesTags: (result) => ['ReportSchema'],
        }),
    }),
});

export const {
    endpoints,
    useGetReportsQuery,
    useGetReportByIdQuery,
    useCreateReportMutation,
    useEditReportMutation,
    useGetReportSchemaQuery,
} = reportsAPI;
export const reportsAPIReducer = reportsAPI.reducer;
export const reportsAPIReducerPath = reportsAPI.reducerPath;
export const reportsAPIReducerMiddleware = reportsAPI.middleware;