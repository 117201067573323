import * as actionTypes from "./MediaActions";
import {updateObject} from "../../utility";

const  initialState = {
    loading_media:false,
    media:{},
    error: {},
};

const mediaReducerUpdater = (state, action) => {
    return updateObject(state,{
        ...action,
    });
};

const mediaReducer = (state=initialState, action) => {
    if (action.type in actionTypes){
        return mediaReducerUpdater(state,action);
    }
    return state;
};

export default mediaReducer;