import { createApi } from '@reduxjs/toolkit/query/react'
import { backend_server_address } from '../../../../../../backendServer';
import { axiosBaseQuery } from '../../../../../../store/utility';

export const dashboardsAPI = createApi({
    reducerPath: 'dashboardsApi',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/analytics/datasource/'}),
    tagTypes: ['DashboardData'],
    endpoints: (builder) => ({
        getDashboardData: builder.query({
            query: (data) => {
                return {
                    url: 'generate/',
                    method: 'POST',
                    withCredentials: true,
                    data: data
                }
            },
            providesTags: (result) => ['DashboardData']
        }),
    }),
});

export const {
    endpoints,
    useGetDashboardDataQuery,
} = dashboardsAPI;
export const dashboardsAPIReducer = dashboardsAPI.reducer;
export const dashboardsAPIReducerPath = dashboardsAPI.reducerPath;
export const dashboardsAPIReducerMiddleware = dashboardsAPI.middleware;