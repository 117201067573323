

export const GET_TREE_START = 'GET_TREE_START';
export const GET_TREE_SUCCESS = 'GET_TREE_SUCCESS';
export const GET_TREE_FAIL = 'GET_TREE_FAIL';

export const EDIT_NODE_START = 'EDIT_NODE_START';
export const EDIT_NODE_SUCCESS = 'EDIT_NODE_SUCCESS';
export const EDIT_NODE_FAIL = 'EDIT_NODE_FAIL';

export const CREATE_NODE_START = 'CREATE_NODE_START';
export const CREATE_NODE_SUCCESS = 'CREATE_NODE_SUCCESS';
export const CREATE_NODE_FAIL = 'CREATE_NODE_FAIL';

export const DELETE_NODE_START = 'DELETE_NODE_START';
export const DELETE_NODE_SUCCESS = 'DELETE_NODE_SUCCESS';
export const DELETE_NODE_FAIL = 'DELETE_NODE_FAIL';
export const CLOSE_CONFIRMATION = 'CLOSE_CONFIRMATION';

export const REBASE_NODE_START = 'REBASE_NODE_START';
export const REBASE_NODE_SUCCESS = 'REBASE_NODE_SUCCESS';
export const REBASE_NODE_FAIL = 'REBASE_NODE_FAIL';

export const SELECT_NODE_START = 'SELECT_NODE_START';
export const SELECT_NODE_SUCCESS = 'SELECT_NODE_SUCCESS';
export const SELECT_NODE_FAIL = 'SELECT_NODE_FAIL';
export const UNSELECT_NODE = 'UNSELECT_NODE';

export const GET_NODE_START = 'GET_NODE_START';
export const GET_NODE_SUCCESS = 'GET_NODE_SUCCESS';
export const GET_NODE_FAIL = 'GET_NODE_FAIL';

export const SET_TREE_GUI_DATA = 'SET_TREE_GUI_DATA';
export const SET_TREE_GUI_CURSOR = 'SET_TREE_GUI_CURSOR';

export const GET_NODE_MEAS_START = 'GET_NODE_MEAS_START';
export const GET_NODE_MEAS_SUCCESS = 'GET_NODE_MEAS_SUCCESS';
export const GET_NODE_MEAS_FAIL = 'GET_NODE_MEAS_FAIL';

export const DELETE_NODE_MEAS_SUCCESS = 'DELETE_NODE_MEAS_SUCCESS';
export const DELETE_NODE_MEAS_FAIL = 'DELETE_NODE_MEAS_FAIL';

export const SET_CURRENT_NODE_MEAS = 'SET_CURRENT_NODE_MEAS';

export const SET_MEAS_INFO_GET = 'SET_MEAS_INFO_GET';
export const GET_MEAS_INFO_START = 'GET_MEAS_INFO_START';
export const GET_MEAS_INFO_SUCCESS = 'GET_MEAS_INFO_SUCCESS';
export const GET_MEAS_INFO_FAIL = 'GET_MEAS_INFO_FAIL';

export const DELETE_MEAS_INFO_SUCCESS = 'DELETE_MEAS_INFO_SUCCESS';
export const DELETE_MEAS_INFO_FAIL = 'DELETE_MEAS_INFO_FAIL';

export const CONFIG_CREATE_EQUIPMENT_START = 'CONFIG_CREATE_EQUIPMENT_START';
export const CONFIG_CREATE_EQUIPMENT_SUCCESS = 'CONFIG_CREATE_EQUIPMENT_SUCCESS';
export const CONFIG_CREATE_EQUIPMENT_FAIL = 'CONFIG_CREATE_EQUIPMENT_FAIL';

export const CONFIG_DELETE_EQUIPMENT_START = 'CONFIG_DELETE_EQUIPMENT_START';
export const CONFIG_DELETE_EQUIPMENT_SUCCESS = 'CONFIG_DELETE_EQUIPMENT_SUCCESS';
export const CONFIG_DELETE_EQUIPMENT_FAIL = 'CONFIG_DELETE_EQUIPMENT_FAIL';

export const CONFIG_EDIT_EQUIPMENT_START = 'CONFIG_EDIT_EQUIPMENT_START';
export const CONFIG_EDIT_EQUIPMENT_SUCCESS = 'CONFIG_EDIT_EQUIPMENT_SUCCESS';
export const CONFIG_EDIT_EQUIPMENT_FAIL = 'CONFIG_EDIT_EQUIPMENT_FAIL';