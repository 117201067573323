import * as actionTypes from "./ErrorLoggingActions";
import {updateObject} from "../../utility";

const  initialState = {
    errorsLog: {}
};

const errorLoggingReducerUpdater = (state, action) => {
    return updateObject(state,{
        ...action,
    });
};

const errorLoggingReducer = (state=initialState, action) => {
    if (action.type in actionTypes){
        return errorLoggingReducerUpdater(state,action);
    }
    return state;
};

export default errorLoggingReducer;