import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import {backend_server_address} from "../../../../../backendServer";

const downloadReportById = createAsyncThunk(
    'reports/downloadById',
    async ({reportId, reportName}, {rejectWithValue}) => {
        return axios.get(backend_server_address +'/api/report/'+reportId+'/download/', {
            withCredentials:true,
            responseType: 'blob',
        })
            .then(res => {
                return res;
            }).catch(error => {
                return rejectWithValue(error.response.data);
        })
    }
);

export const reportsDownloadSlice = createSlice({
    name: 'reportsDownload',
    initialState: {error: null, loading: 'idle'},
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(downloadReportById.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending';
                }
            })
            .addCase(downloadReportById.fulfilled, (state, action) => {
                // console.log('reports download slice, fullfilled case', {state, action});
                if (state.loading === 'pending') {
                    window.gtag('event', 'report_download', {
                        'event_label': action.meta.arg.reportName,
                        'event_category': 'report',
                    });
                    state.loading = 'idle';
                    state.error = null;

                    let res = action.payload;
                    let url = window.URL.createObjectURL(res.data);
                    url = window.URL.createObjectURL(
                        new Blob([res.data], { type: res.data.type })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute('download', action.meta.arg.reportName);

                    document.body.appendChild(link);

                    link.click();
                }
            })
            .addCase(downloadReportById.rejected, (state, action) => {
                if (state.loading === 'pending') {
                    state.loading = 'idle';
                    state.error = action.error
                }
            })

    }
});

// Action creators are generated for each case reducer function
// const { setDatesR } = dateSelectorSlice.actions;

export const downloadReport = (params) => downloadReportById(params);
export const selectLoadingState = () => (state) => state.reportsDownload ? state.reportsDownload.loading === 'pending':false;
export const selectDownloadError = () => (state) => state.reportsDownload ? state.reportsDownload.error:undefined;
const reportsDownload = reportsDownloadSlice.reducer;
export default reportsDownload;
