import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../../../../store/utility';
import { backend_server_address } from '../../../../../backendServer';

const toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const mediaAPI = createApi({
    reducerPath: 'mediaAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/v2/media/'}),
    tagTypes: ['MediaList', 'MediaFile', 'MediaThumbnail', 'MediaMetadata'],
    endpoints: (builder) => ({
        getMediaList: builder.query({
            query: (params) => {
                return {
                    url: ``,
                    method: 'GET',
                    withCredentials: true,
                    params
                }
            },
            providesTags: (result, error, {node_id}) => [{type: 'MediaList', id: node_id}],
        }),
        getMediaFile: builder.query({
            query: (id) => {
                return {
                    url: `${id}/`,
                    method: 'GET',
                    withCredentials: true,
                    responseType: 'blob'
                }
            },
            transformResponse: async (result) => {
                const base64Image = await toBase64(result);
                return base64Image;
            },
            providesTags: (result, error, id) => [{type: 'MediaFile', id}],
        }),
        getMediaThumbnail: builder.query({
            query: (id) => {
                return {
                    url: `${id}/thumbnail/`,
                    method: 'GET',
                    withCredentials: true,
                    responseType: 'blob'
                }
            },
            transformResponse: async (result) => {
                const base64Image = await toBase64(result);
                return base64Image;
            },
            providesTags: (result, error, id) => [{type: 'MediaThumbnail', id}],
        }),
        getMediaMetadata: builder.query({
            query: (id) => {
                return {
                    url: `${id}/metadata/`,
                    method: 'GET',
                    withCredentials: true,
                    responseType: 'blob'
                }
            },
            transformResponse: async (result) => {
                const base64Image = await toBase64(result);
                return base64Image;
            },
            providesTags: (result, error, id) => [{type: 'MediaMetadata', id}],
        }),
        uploadMediaFile: builder.mutation({
            query: (data) => {
                return {
                    url: ``,
                    method: 'POST',
                    withCredentials: true,
                    data
                }
            },
            invalidatesTags: (result, error, {node_id}) => [{type: 'MediaList', id: node_id}],
        }),
    }),
});

export const {
    endpoints,
    useGetMediaListQuery,
    useGetMediaFileQuery,
    useGetMediaThumbnailQuery,
    useGetMediaMetadataQuery,
    useUploadMediaFileMutation,
} = mediaAPI;

export const mediaAPIReducer = mediaAPI.reducer;
export const mediaAPIReducerPath = mediaAPI.reducerPath;
export const mediaAPIReducerMiddleware = mediaAPI.middleware;