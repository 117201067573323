import {Route, Redirect, Switch} from 'react-router-dom';
import React, {Suspense, useEffect, useState, lazy} from 'react';
import Loader from 'react-loaders'
import {setMobileState} from "../store/actions/themeActions/ThemeOptions";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {importRetryReload} from "./utilities/importRetryReload";
import i18n from './utilities/i18n';
const Platform = lazy(() => importRetryReload( () => import( "./platform")));
const Login = lazy(() => importRetryReload( () =>import( "./landing/Login")));
const  ForgotPassword = lazy(() => importRetryReload( () => import( "./landing/ForgotPassword")));
const RecoverPassword = lazy(() => importRetryReload(() => import( "./landing/NewPassword")));


const Notfound = () => <h1>Not found</h1>;

const AppMain = (props) => {

    const [previousPath, setPreviousPath] = useState("/");
    const [currentPath, setCurrentPath] = useState(props.location.pathname);

    useEffect( () => {
        // console.log({'width change': props.width});
        if(props.width < 768){
            props.setMobileState(2);
        }
        else if(props.width < 992){
            props.setMobileState(1);
        }
        else{
            props.setMobileState(0);
        }
    },[props.width]);

    useEffect( () => {
        if(props.location.pathname !== currentPath) {
            setPreviousPath(currentPath);
            setCurrentPath(props.location.pathname);
            // setLatestVersion(undefined);
            // setRetries(3);
            // console.log('Current Path: ' + props.location.pathname)
            // if(trackingId !== undefined) {
            //     ReactGA.set({page: props.location.pathname}); // Update the user's current page
            //     ReactGA.pageview(props.location.pathname);
            // }
        }
    }, [props.location.pathname]);

    return (
        <div>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="line-scale-party"/>
                        </div>
                        <h6 className="mt-3">
                            {i18n.t('Loading module')}

                        </h6>
                    </div>
                </div>
            }>
                {
                    props.prevAuth ?
                        (props.isAuthenticated
                            // && !isLoadingVersion && !isUninitializedVersion && !versionError
                        ) ?
                            // !versionError ?
                                <Switch>
                                    <Route exact path="/login" render={() => (
                                        <Redirect to={previousPath}/>
                                    )}/>
                                    <Route path="/dashboard" component={Platform}/>
                                    <Route path="/" exact render={() => (
                                        <Redirect to="/dashboard"/>
                                    )}/>
                                    <Route component={Notfound}/>
                                </Switch>
                                // :
                                // <div className="loader-container">
                                //     <div className="loader-container-inner">
                                //         <div className="text-center">
                                //             <Loader type="line-scale-party"/>
                                //         </div>
                                //         <h6 className="mt-3">
                                //             Error de versión. <br/>
                                //             Ante cualquier duda contacte a su analista designado
                                //         </h6>
                                //         <Button onClick={()=> {window.location.reload()}}>
                                //             Refrescar página
                                //         </Button>
                                //     </div>
                                // </div>
                            :
                            <div className="loader-container">
                                <div className="loader-container-inner">
                                    <div className="text-center">
                                        <Loader type="line-scale-party"/>
                                    </div>
                                    <h6 className="mt-3">
                                        {i18n.t('Authenticating')}
                                    </h6>
                                </div>
                            </div>
                        :
                        <Switch>
                            <Route path="/login" exact component={Login}/>
                            <Route path="/recover" exact component={RecoverPassword}/>
                            <Route path="/dashboard" render={() => (
                                <Redirect to="/login"/>
                            )}/>
                            <Route path="/forgot-password" exact component={ForgotPassword}/>
                            <Route  render={() => (
                                <Redirect to="/login"/>
                            )}/>
                        </Switch>
                }

            </Suspense>


        </div>


    )
};

const mapStateToProp = state => ({
});

const mapDispatchToProps = dispatch => ({
    setMobileState: (state) => dispatch(setMobileState(state)),
});

export default withRouter(connect(mapStateToProp,mapDispatchToProps)(AppMain));