import { createApi } from '@reduxjs/toolkit/query/react'
import {backend_server_address} from "../../../../../backendServer";
import { axiosBaseQuery } from '../../../../../store/utility';
import {MechanicalComponentQuery, MechanicalComponentResponse} from './types';

// Define a service using a base URL and expected endpoints
export const configurationAPI = createApi({
    reducerPath: 'configurationApi',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/equipment_nodes/configuration/'}),
    tagTypes: ['ConfigurationData', 'ConfigurationSchema', 'ConfigurationAnalysis', 'MechanicalComponent'],
    endpoints: (builder) => ({
        getConfigData: builder.query({
            query: (node_id) => {
                return {
                    url: node_id + '/',
                    method: 'GET',
                    withCredentials: true,
                }
            },
            providesTags: (result, error, id) => [{type: 'ConfigurationData', id}],
        }),
        updateConfigData: builder.mutation({
            query: ({node_id, ...data}) => {
                return {
                    url: node_id + '/',
                    method: 'PUT',
                    withCredentials: true,
                    data: {data}
                }
            },
            invalidatesTags: (result, error, {node_id}) => [{type: 'ConfigurationData', id: node_id}, {type: 'ConfigurationAnalysis', id: node_id}],
        }),
        duplicateConfigData: builder.mutation({
            query: ({node_id, dest}) => {
                return {
                    url: node_id + '/duplicate/',
                    method: 'PUT',
                    withCredentials: true,
                    data: {dest}
                }
            },
            invalidatesTags: (result, error, {dest}) => dest.map((id: number) => [{type: 'ConfigurationData', id}, {type: 'ConfigurationAnalysis', id}]),
        }),
        getConfigSchema: builder.query({
            query: (node_id) => {
                return {
                    url: node_id + '/schema/',
                    method: 'GET',
                    withCredentials: true,
                }
            },
            providesTags: (result, error, id) => [{type: 'ConfigurationSchema', id}],
        }),
        getComponents: builder.query<MechanicalComponentResponse, MechanicalComponentQuery>({
            query: (node_id) => {
                return {
                    url: node_id + '/components/',
                    method: 'GET',
                    withCredentials: true,
                }
            },
            providesTags: (response) => (response && response.data) ?
                [...response.data.map(component => ({ type:'MechanicalComponent' as const, id:component.id})), 'MechanicalComponent']
                :[ 'MechanicalComponent' ],
        }),
        bulkUploadConfigData: builder.mutation({
            query: ({data}) => {
                return {
                    url: 'bulk/',
                    method: 'POST',
                    withCredentials: true,
                    data: data
                }
            },
            invalidatesTags: (result, error, {id}) => [{type: 'ConfigurationData', id}, {type: 'ConfigurationSchema', id}],
        }),
        getConfigAnalysis: builder.query({
            query: (node_id) => {
                return {
                    url: node_id + '/analysis/',
                    method: 'GET',
                    withCredentials: true,
                }
            },
            providesTags: (result, error, id) => [{type: 'ConfigurationAnalysis', id}],
        }),
    }),
});

export const {
    endpoints,
    useGetConfigDataQuery,
    useUpdateConfigDataMutation,
    useDuplicateConfigDataMutation,
    useGetConfigSchemaQuery,
    useBulkUploadConfigDataMutation,
    useGetConfigAnalysisQuery,
    useGetComponentsQuery,
} = configurationAPI;
export const configurationAPIReducer = configurationAPI.reducer;
export const configurationAPIReducerPath = configurationAPI.reducerPath;
export const configurationAPIReducerMiddleware = configurationAPI.middleware;