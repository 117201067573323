import { createApi } from '@reduxjs/toolkit/query/react'
import {backend_server_address} from "../../../../../backendServer";
import { axiosBaseQuery } from '../../../../../store/utility';
import { BearingsQuery, BearingsResponse } from '../components/bearingPicker/types';

// Define a service using a base URL and expected endpoints
export const bearingsAPI = createApi({
    reducerPath: 'bearingsApi',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/equipment_nodes/bearing/'}),
    tagTypes: ['Bearing'],
    endpoints: (builder) => ({
        getBearingsData: builder.query<BearingsResponse, BearingsQuery>({
            query: (params) => ({
                url: '',
                method: 'GET',
                withCredentials:true,
                params: params
            }),
            providesTags: (response) => {
                return response ?
                    [...response.results.map((bearing) => ({ type:'Bearing' as const, id:bearing.id})), 'Bearing']
                    :[ 'Bearing' as const]
            }
        }),
    }),
});

export const {
    endpoints,
    useGetBearingsDataQuery,
} = bearingsAPI;
export const bearingsAPIReducer = bearingsAPI.reducer;
export const bearingsAPIReducerPath = bearingsAPI.reducerPath;
export const bearingsAPIReducerMiddleware = bearingsAPI.middleware;