import * as actionTypes from "./TagsActions";
import {updateObject} from "../../utility";

const  initialState = {
    tag_nodes:{},
};

const tagsReducerUpdater = (state, action) => {
    return updateObject(state,{
        ...action,
    });
};

const tagsReducer = (state=initialState, action) => {
    if (action.type in actionTypes){
        return tagsReducerUpdater(state,action);
    }
    return state;
};

export default tagsReducer;