import React from 'react';
import { useResizeDetector } from 'react-resize-detector';

const withResizeDetector = (WrappedComponent) => {
    return (props) => {

        const { width, height, ref } = useResizeDetector({
            refreshMode: 'debounce',
            refreshRate: 0,
            onResize: props.onResize
        });

        return <WrappedComponent {...props} width={width} height={height} resizeRef={ref} />;
    }
};

export default withResizeDetector;