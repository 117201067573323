import { createApi } from '@reduxjs/toolkit/query/react'
import {backend_server_address} from "../../../../../backendServer";
import axios from "axios";


const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: '' }) =>
        async (axiosParams) => {
            try {
                axiosParams.url = baseUrl + axiosParams.url;
                const result = await axios.request(axiosParams);
                return { data: result.data }
            } catch (axiosError) {
                let err = axiosError;
                return {
                    error: { status: err.response ? err.response.status:undefined, data: err.response ? err.response.data:undefined },
                }
            }
        };

// Define a service using a base URL and expected endpoints
export const usersAPI = createApi({
    reducerPath: 'usersAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/login/user/'}),
    tagTypes: ['UserList', 'User', 'UserSchema'],
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: (params) => ({
                url: 'list/',
                method: 'GET',
                withCredentials:true,
                // params:params
            }),
            providesTags: (result) => {
                return (result && result.data) ?
                    [{ type:'UserList'}, 'UserList']
                    :[ 'UserList' ]
            }
        }),
        getUserSchema: builder.query({
                query: (params) => ({
                    url: 'schema/',
                    method: 'GET',
                    withCredentials: true,
                    // params: params
                }),
                providesTags: result => {
                    return result ?
                        [{type: 'UserSchema'}] : ['UserSchema']
                }
            }
        ),
        getUserByUsername: builder.query({
            query: (params) => ({
                url: 'get/',
                method: 'GET',
                withCredentials:true,
                params: params
            }),
            providesTags: (result) => {
                // console.log([{type: 'Pending', id: result.id}])
                return (result && result.data) ? [{type: 'User', id: result.data.id}]: ['User'];
            },
        }),
        editUser: builder.mutation({
            query: (data) => {
                window.gtag('event', 'user_edit', {
                    'event_category': 'user',
                });
                return {
                    url: 'edit/',
                    method: 'POST',
                    withCredentials: true,
                    // username, node_id, first_name, last_name, phone, group
                    data: data
                }
            },
            invalidatesTags: (result) => (result && result.data)?
                [{ type: 'User', id:result.data.id }]:['User'],
        }),
        createUser: builder.mutation({
            query: (data) => {
                window.gtag('event', 'user_create', {
                    'event_category': 'user',
                });
                return {
                    url: 'create/',
                    method: 'POST',
                    withCredentials: true,
                    // username, password, node_id, first_name, last_name, phone, group
                    data: data
                }
            },
            invalidatesTags: (result) => ['User'],
        }),
    }),
});

export const {
    useGetUsersQuery,
    useGetUserSchemaQuery,
    useGetUserByUsernameQuery,
    useEditUserMutation,
    useCreateUserMutation,
} = usersAPI;
export const usersAPIReducer = usersAPI.reducer;
export const usersAPIReducerPath = usersAPI.reducerPath;
export const usersAPIReducerMiddleware = usersAPI.middleware;