import React from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import {withRouter} from 'react-router-dom';
import * as actions from '../store/actions/authActions/auth';
import AppMain from './AppMain';
import {isAuthenticated} from "../store/actions/authActions/auth"
import * as Sentry from "@sentry/react";
import withResizeDetector from './utilities/resizeDetector';



class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            closedSmallerSidebar: false
        };
        this.props.onTryAutoSignup();
    }

    render() {
        let {
            colorScheme,
            enableFixedHeader,
            enableFixedSidebar,
            enableFixedFooter,
            enableClosedSidebar,
            closedSmallerSidebar,
            enableMobileMenu,
            enablePageTabsAlt,
            width,
            resizeRef
        } = this.props;

        let authenticated = isAuthenticated()  && this.props.authenticated;

        return (
            <div 
                ref={resizeRef}
                className={cx(
                    "app-container app-theme-" + colorScheme,
                    {'fixed-header': enableFixedHeader},
                    {'fixed-sidebar': enableFixedSidebar || width < 975},
                    {'fixed-footer': enableFixedFooter},
                    {'closed-sidebar': enableClosedSidebar || width < 975},
                    {'closed-sidebar-mobile': closedSmallerSidebar || width < 975},
                    {'sidebar-mobile-open': enableMobileMenu},
                    {'body-tabs-shadow-btn': enablePageTabsAlt},
                )}
            >
                <AppMain {...{...this.props, isAuthenticated:authenticated, prevAuth:isAuthenticated()}} width={width}>

                </AppMain>
            </div>
        )
    }
}

const mapStateToProp = state => ({
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
    //Added for authentication
    authenticated: state.authReducers.authenticated,
    // isAuthenticated: state.authReducers.token_access !== null,
    // authState: state.authReducers.authenticated,
    // waiting_authentication: state.authReducers.waiting_authentication,
});

const mapDispatchToProps = dispatch => ({
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    refreshToken: () => dispatch(actions.authRefreshToken()),
});

export default withRouter(connect(mapStateToProp,mapDispatchToProps)(Sentry.withProfiler(withResizeDetector(Main))));