import {problemsAPI} from "./problemsDuck";
import {findingsAPI} from "./findingsDuck";
import {evidenceAPI} from "./evidenceDuck";
import {featuresAPI} from "./featuresDuck";
import {diagnosticsAPI} from "./diagnosticsDuck";

export const {
    useGetProblemTypesQuery,
    useGetProblemsQuery,
    useCreateProblemMutation,
    useEditProblemMutation,
} = problemsAPI;
export const problemsAPIReducer = problemsAPI.reducer;
export const problemsAPIReducerPath = problemsAPI.reducerPath;
export const problemsAPIReducerMiddleware = problemsAPI.middleware;

export const {
    useGetFindingTypesQuery,
    useGetFindingsQuery,
    useCreateFindingMutation,
} = findingsAPI;
export const findingsAPIReducer = findingsAPI.reducer;
export const findingsAPIReducerPath = findingsAPI.reducerPath;
export const findingsAPIReducerMiddleware = findingsAPI.middleware;

export const {
    useGetEvidenceQuery,
    useGetEvidenceByIdQuery,
    useCreateEvidenceMutation,
} = evidenceAPI;
export const evidenceAPIReducer = evidenceAPI.reducer;
export const evidenceAPIReducerPath = evidenceAPI.reducerPath;
export const evidenceAPIReducerMiddleware = evidenceAPI.middleware;

export const {
    endpoints,
    useGetDiagnosticsQuery,
    useGetDiagnosticByIdQuery,
    useCreateDiagnosticMutation,
    useGetLastDiagnosticsQuery,
    useEditDiagnosticMutation,
    useEditWorkOrderMutation,
    useGetDiagnosticTrackingQuery,
} = diagnosticsAPI;
export const diagnosticsAPIReducer = diagnosticsAPI.reducer;
export const diagnosticsAPIReducerPath = diagnosticsAPI.reducerPath;
export const diagnosticsAPIReducerMiddleware = diagnosticsAPI.middleware;

export const {
    endpoints: featuresEndpoints,
    useGetFeaturesQuery,
    useGetFeatureByIdQuery,
} = featuresAPI;
export const featuresAPIReducer = featuresAPI.reducer;
export const featuresAPIReducerPath = featuresAPI.reducerPath;
export const featuresAPIReducerMiddleware = featuresAPI.middleware;