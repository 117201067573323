export const GET_HARDWARE_LIST_START = 'GET_HARDWARE_LIST_START';
export const GET_HARDWARE_LIST_SUCCESS = 'GET_HARDWARE_LIST_SUCCESS';
export const GET_HARDWARE_LIST_FAIL = 'GET_HARDWARE_LIST_FAIL';

export const GET_HARDWARE_LINK_LIST_START = 'GET_HARDWARE_LINK_LIST_START';
export const GET_HARDWARE_LINK_LIST_SUCCESS = 'GET_HARDWARE_LINK_LIST_SUCCESS';
export const GET_HARDWARE_LINK_LIST_FAIL = 'GET_HARDWARE_LINK_LIST_FAIL';

export const HARDWARE_EDIT_START = 'HARDWARE_EDIT_START';
export const HARDWARE_EDIT_SUCCESS = 'HARDWARE_EDIT_SUCCESS';
export const HARDWARE_EDIT_FAIL = 'HARDWARE_EDIT_FAIL';

export const HARDWARE_LINK_CREATE_START = 'HARDWARE_LINK_CREATE_START';
export const HARDWARE_LINK_CREATE_SUCCESS = 'HARDWARE_LINK_CREATE_SUCCESS';
export const HARDWARE_LINK_CREATE_FAIL = 'HARDWARE_LINK_CREATE_FAIL';

export const HARDWARE_LINK_DELETE_START = 'HARDWARE_LINK_DELETE_START';
export const HARDWARE_LINK_DELETE_SUCCESS = 'HARDWARE_LINK_DELETE_SUCCESS';
export const HARDWARE_LINK_DELETE_FAIL = 'HARDWARE_LINK_DELETE_FAIL';

export const HARDWARE_GET_SCHEMA_COMMAND_START = 'HARDWARE_GET_SCHEMA_COMMAND_START';
export const HARDWARE_GET_SCHEMA_COMMAND_SUCCESS = 'HARDWARE_GET_SCHEMA_COMMAND_SUCCESS';
export const HARDWARE_GET_SCHEMA_COMMAND_FAIL = 'HARDWARE_GET_SCHEMA_COMMAND_FAIL';

export const HARDWARE_SEND_COMMAND_START = 'HARDWARE_SEND_COMMAND_START';
export const HARDWARE_SEND_COMMAND_SUCCESS = 'HARDWARE_SEND_COMMAND_SUCCESS';
export const HARDWARE_SEND_COMMAND_FAIL = 'HARDWARE_SEND_COMMAND_FAIL';

export const HARDWARE_GET_TREE_START ='HARDWARE_GET_TREE_START';
export const HARDWARE_GET_TREE_SUCCESS ='HARDWARE_GET_TREE_SUCCESS';
export const HARDWARE_GET_TREE_FAIL ='HARDWARE_GET_TREE_FAIL';

export const HARDWARE_CLEAR_TREE ='HARDWARE_CLEAR_TREE';

