export const CONFIG_GET_EQUIPMENT_SCHEMA_START = 'CONFIG_GET_EQUIPMENT_SCHEMA_START';
export const CONFIG_GET_EQUIPMENT_SCHEMA_SUCCESS = 'CONFIG_GET_EQUIPMENT_SCHEMA_SUCCESS';
export const CONFIG_GET_EQUIPMENT_SCHEMA_FAIL = 'CONFIG_GET_EQUIPMENT_SCHEMA_FAIL';

export const CONFIG_CREATE_EQUIPMENT_START = 'CONFIG_CREATE_EQUIPMENT_START';
export const CONFIG_CREATE_EQUIPMENT_SUCCESS = 'CONFIG_CREATE_EQUIPMENT_SUCCESS';
export const CONFIG_CREATE_EQUIPMENT_FAIL = 'CONFIG_CREATE_EQUIPMENT_FAIL';

export const CONFIG_DELETE_EQUIPMENT_START = 'CONFIG_DELETE_EQUIPMENT_START';
export const CONFIG_DELETE_EQUIPMENT_SUCCESS = 'CONFIG_DELETE_EQUIPMENT_SUCCESS';
export const CONFIG_DELETE_EQUIPMENT_FAIL = 'CONFIG_DELETE_EQUIPMENT_FAIL';

export const CONFIG_EDIT_EQUIPMENT_START = 'CONFIG_EDIT_EQUIPMENT_START';
export const CONFIG_EDIT_EQUIPMENT_SUCCESS = 'CONFIG_EDIT_EQUIPMENT_SUCCESS';
export const CONFIG_EDIT_EQUIPMENT_FAIL = 'CONFIG_EDIT_EQUIPMENT_FAIL';