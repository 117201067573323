export const GET_ALARMS_COUNT_START = 'GET_ALARMS_COUNT_START';
export const GET_ALARMS_COUNT_SUCCESS = 'GET_ALARMS_COUNT_SUCCESS';
export const GET_ALARMS_COUNT_FAIL = 'GET_ALARMS_COUNT_FAIL';

export const GET_MACHINE_HEALTH_COUNT_START = 'GET_MACHINE_HEALTH_COUNT_START';
export const GET_MACHINE_HEALTH_COUNT_SUCCESS = 'GET_MACHINE_HEALTH_COUNT_SUCCESS';
export const GET_MACHINE_HEALTH_COUNT_FAIL = 'GET_MACHINE_HEALTH_COUNT_FAIL';

export const GET_MACHINE_HEALTH_HISTORY_COUNT_START = 'GET_MACHINE_HEALTH_HISTORY_COUNT_START';
export const GET_MACHINE_HEALTH_HISTORY_COUNT_SUCCESS = 'GET_MACHINE_HEALTH_HISTORY_COUNT_SUCCESS';
export const GET_MACHINE_HEALTH_HISTORY_COUNT_FAIL = 'GET_MACHINE_HEALTH_HISTORY_COUNT_FAIL';

export const GET_PRODUCTION_COUNT_START = 'GET_PRODUCTION_COUNT_START';
export const GET_PRODUCTION_COUNT_SUCCESS = 'GET_PRODUCTION_COUNT_SUCCESS';
export const GET_PRODUCTION_COUNT_FAIL = 'GET_PRODUCTION_COUNT_FAIL';